export const CollegesData = [

    {
        src: "https://ik.imagekit.io/mhcockpit1/Colleges/VELS%20Building.webp",
        title: "Vels Institute of Science Technology and Advanced Studies.",
        location: "Chennai.",
        ugc: "UGC Recognised",
        courses: "B.Sc. Aeronautical science | B.Sc Aviation | BBA Aviation | MBA Aviation | Diploma in Cabin Crew | Diploma in Airport Management | PG Diploma in Aircraft Maintenance",
        button: "To Apply",
        link: "/vels-university"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/Colleges/MRU.webp",
        title: "Malla Reddy University.",
        location: "Hyderabad.",
        ugc: "UGC Recognised",
        courses: "BBA in Aviation and Tourism Management | BBA in Logistics & Supply Chain | MBA HR, Marketing Finance with Aviation electives | MBA in Logistics and Supply Chain Management",
        button: "To Apply",
        link: "https://univirteducation.in/"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/Colleges/CRESCENT.jpg",
        title: "Crescent University.",
        location: "Chennai.",
        ugc: "UGC Recognised",
        courses: "B.Sc Aviation",
        button: "To Apply",
        link: "/crescent-landing"
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/Colleges/JMC.webp",
        title: "Jamal Mohamed College.",
        location: "Trichy.",
        ugc: "UGC Recognised",
        courses: "BBA Aviation",
        button: "To Apply",
        link: "/jamal-mohamed-college"
    }

]


export const courses = {
    'BSc': ['Aviation', 'Aeronautical Science'],
    'BBA': ['Aviation', 'Aviation & Tourism Management', 'Logistics & Supply Chain Management'],
    'MBA': ['Aviation & Airport Management', 'HR, Marketing Finance with Aviation electives', 'Logistics & Supply Chain Management'],
    'Diploma': ['Cabin Crew', 'Airport & Airline Management'],
    'PG Diploma': ['Aircraft Maintenance'],
    // "B.Tech" : ['Aerospace Engineering'],
};

export const colleges = {
    'BSc-Aviation': [
        {
            image: 'https://ik.imagekit.io/mhcockpit1/Colleges/VELS%20Building.webp',
            name: 'Vels University.',
            courses: "B.Sc. Aeronautical science | B.Sc Aviation | BBA Aviation | MBA Aviation & Airport Management | Diploma in Cabin Crew | Diploma in Airline & Airport Management | PG Diploma in Aircraft Maintenance",
            location: 'Chennai.',
            accreditation: '(UGC Recognised)',
            applyNow: '/vels-university',
        },
        {
            image: 'https://ik.imagekit.io/mhcockpit1/Colleges/CRESCENT.jpg',
            name: 'Crescent University.',
            courses: "B.Sc Aviation",
            location: 'Chennai.',
            accreditation: '(UGC Recognised)',
            applyNow: '/crescent-landing',
        }
    ],
    'BSc-Aeronautical Science': [
        {
            image: 'https://ik.imagekit.io/mhcockpit1/Colleges/VELS%20Building.webp',
            name: 'Vels University.',
            courses: "B.Sc. Aeronautical science | B.Sc Aviation | BBA Aviation | MBA Aviation & Airport Management | Diploma in Cabin Crew | Diploma in Airline & Airport Management | PG Diploma in Aircraft Maintenance",
            location: 'Chennai.',
            accreditation: '(UGC Recognised)',
            applyNow: '/vels-university',
        },
    ],
    'BBA-Aviation': [
        {
            image: 'https://ik.imagekit.io/mhcockpit1/Colleges/VELS%20Building.webp',
            name: 'Vels University.',
            courses: "B.Sc. Aeronautical science | B.Sc Aviation | BBA Aviation | MBA Aviation & Airport Management | Diploma in Cabin Crew | Diploma in Airline & Airport Management | PG Diploma in Aircraft Maintenance",
            location: 'Chennai.',
            accreditation: '(UGC Recognised)',
            applyNow: '/vels-university',
        },
        {
            image: 'https://ik.imagekit.io/mhcockpit1/Colleges/MRU.webp',
            name: 'Malla Reddy University.',
            courses: "BBA Aviation and Tourism Management | BBA Logistics & Supply Chain Management | MBA HR, Marketing Finance with Aviation electives | MBA Logistics and Supply Chain Management",
            location: 'Hyderabad.',
            accreditation: '(UGC Recognised)',
            applyNow: 'https://univirteducation.in/',
        },
        {
            image: 'https://ik.imagekit.io/mhcockpit1/Colleges/JMC.webp',
            name: 'Jamal Mohamed College.',
            courses: "BBA Aviation",
            location: 'Trichy.',
            accreditation: '(UGC Recognised)',
            applyNow: '/jamal-mohamed-college',
        },
    ],
    'BBA-Aviation & Tourism Management': [
        {
            image: 'https://ik.imagekit.io/mhcockpit1/Colleges/MRU.webp',
            name: 'Malla Reddy University.',
            courses: "BBA Aviation and Tourism Management | BBA Logistics & Supply Chain Management | MBA HR, Marketing Finance with Aviation electives | MBA Logistics and Supply Chain Management",
            location: 'Hyderabad.',
            accreditation: '(UGC Recognised)',
            applyNow: 'https://univirteducation.in/',
        },
    ],
    'BBA-Logistics & Supply Chain Management': [
        {
            image: 'https://ik.imagekit.io/mhcockpit1/Colleges/MRU.webp',
            name: 'Malla Reddy University.',
            courses: "BBA Aviation and Tourism Management | BBA Logistics & Supply Chain Management | MBA HR, Marketing Finance with Aviation electives | MBA Logistics and Supply Chain Management",
            location: 'Hyderabad.',
            accreditation: '(UGC Recognised)',
            applyNow: 'https://univirteducation.in/',
        },
    ],
    'MBA-Aviation & Airport Management': [
        {
            image: 'https://ik.imagekit.io/mhcockpit1/Colleges/VELS%20Building.webp',
            name: 'Vels University.',
            courses: "B.Sc. Aeronautical science | B.Sc Aviation | BBA Aviation | MBA Aviation & Airport Management | Diploma in Cabin Crew | Diploma in Airline & Airport Management | PG Diploma in Aircraft Maintenance",
            location: 'Chennai.',
            accreditation: '(UGC Recognised)',
            applyNow: '/vels-university',
        },
        {
            image: 'https://ik.imagekit.io/mhcockpit1/Colleges/MRU.webp',
            name: 'Malla Reddy University.',
            courses: "BBA Aviation and Tourism Management | BBA Logistics & Supply Chain Management | MBA HR, Marketing Finance with Aviation electives | MBA Logistics and Supply Chain Management",
            location: 'Hyderabad.',
            accreditation: '(UGC Recognised)',
            applyNow: 'https://univirteducation.in/',
        }
    ],
    'MBA-HR, Marketing Finance with Aviation electives': [
        {
            image: 'https://ik.imagekit.io/mhcockpit1/Colleges/MRU.webp',
            name: 'Malla Reddy University.',
            courses: "BBA Aviation and Tourism Management | BBA Logistics & Supply Chain Management | MBA HR, Marketing Finance with Aviation electives | MBA Logistics and Supply Chain Management",
            location: 'Hyderabad.',
            accreditation: '(UGC Recognised)',
            applyNow: 'https://univirteducation.in/',
        }
    ],
    'MBA-Logistics & Supply Chain Management': [
        {
            image: 'https://ik.imagekit.io/mhcockpit1/Colleges/MRU.webp',
            name: 'Malla Reddy University.',
            courses: "BBA Aviation and Tourism Management | BBA Logistics & Supply Chain Management | MBA HR, Marketing Finance with Aviation electives | MBA Logistics and Supply Chain Management",
            location: 'Hyderabad.',
            accreditation: '(UGC Recognised)',
            applyNow: 'https://univirteducation.in/',
        }
    ],
    'Diploma-Cabin Crew': [
        {
            image: 'https://ik.imagekit.io/mhcockpit1/Colleges/VELS%20Building.webp',
            name: 'Vels University.',
            courses: "B.Sc. Aeronautical science | B.Sc Aviation | BBA Aviation | MBA Aviation & Airport Management | Diploma in Cabin Crew | Diploma in Airline & Airport Management | PG Diploma in Aircraft Maintenance",
            location: 'Chennai.',
            accreditation: '(UGC Recognised)',
            applyNow: '/vels-university',
        },
    ],
    'Diploma-Airport & Airline Management': [
        {
            image: 'https://ik.imagekit.io/mhcockpit1/Colleges/VELS%20Building.webp',
            name: 'Vels University.',
            courses: "B.Sc. Aeronautical science | B.Sc Aviation | BBA Aviation | MBA Aviation & Airport Management | Diploma in Cabin Crew | Diploma in Airline & Airport Management | PG Diploma in Aircraft Maintenance",
            location: 'Chennai.',
            accreditation: '(UGC Recognised)',
            applyNow: '/vels-university',
        },
    ],
    'PG Diploma-Aircraft Maintenance': [
        {
            image: 'https://ik.imagekit.io/mhcockpit1/Colleges/VELS%20Building.webp',
            name: 'Vels University.',
            courses: "B.Sc. Aeronautical science | B.Sc Aviation | BBA Aviation | MBA Aviation & Airport Management | Diploma in Cabin Crew | Diploma in Airline & Airport Management | PG Diploma in Aircraft Maintenance",
            location: 'Chennai.',
            accreditation: '(UGC Recognised)',
            applyNow: '/vels-university',
        },
    ],
    // 'B.Tech-Aerospace Engineering': [
    //     {
    //         image: 'https://ik.imagekit.io/mhcockpit1/Colleges/VELS%20Building.webp',
    //         name: 'Vels University.',
    //         courses: "B.Sc. Aeronautical science | B.Sc Aviation | BBA Aviation | MBA Aviation & Airport Management | Diploma in Cabin Crew | Diploma in Airline & Airport Management | PG Diploma in Aircraft Maintenance",
    //         location: 'Chennai.',
    //         accreditation: '(UGC Recognised)',
    //         applyNow: '/vels-university',
    //     },
    // ],
};