import React from 'react'
import FadeInSectionDownToUp from '../../../Components/FadeAnimations/FadeInSectionDownToUp'
import Marquee from 'react-fast-marquee'

export default function CrescentLanding() {

    // const CrescentLandingPlacedRow1 = [

    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/Swarna.png"
    //     },
    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/Joshua.png"
    //     },
    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/sanjay.png"
    //     },
    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/yahkoob.png"
    //     },
    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/Hariharan.png"
    //     },
    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/Merlin.png"
    //     }

    // ]

    // const CrescentLandingPlacedRow2 = [

    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/Priyanka.png"
    //     },
    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/sneha.png"
    //     },
    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/Nivetha.png"
    //     },
    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/adil.png"
    //     },
    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/Ragapriya.png"
    //     },
    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/Prasanth.png"
    //     },
    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/Raqueeb.png"
    //     },

    // ]

    // const CrescentLandingPlacedRow3 = [

    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/Henry.png"
    //     },
    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/Irshath.png"
    //     },
    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/Sneha-g.png"
    //     },
    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/Shabin.png"
    //     },
    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/Alsherif.png"
    //     },
    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/Akilan.png"
    //     },
    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/silvio.png"
    //     },

    // ]
    // const CrescentLandingPlacedRow4 = [

    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/Gurukaviya.png"
    //     },
    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/Saran.png"
    //     },
    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/vlog-viki.png"
    //     },
    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/bala.png"
    //     },
    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/rehan.png"
    //     },
    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/Sana.png"
    //     },

    // ]

    const Placed1 = Array.from({ length: 36 }, (_, i) => {
        const url = `https://ik.imagekit.io/mhcockpit1/Students/Placed%20Students/${i + 1}.png`;
        // Check if the URL should be excluded
        if (url === "https://ik.imagekit.io/mhcockpit1/Students/Placed%20Students/3.png" || url === "https://ik.imagekit.io/mhcockpit1/Students/Placed%20Students/7.png") {
            return null;  // Return null for the URLs to be excluded
        }
        return { src: url };
    }).filter(item => item !== null);

    const Placed2 = Array.from({ length: 36 }, (_, i) => ({
        src: `https://ik.imagekit.io/mhcockpit1/Students/Placed%20Students/${i + 36}.png`,
    }));

    return (
        <div>
            <div>
                <img src='https://ik.imagekit.io/mhcockpit1/Crescent%20Landing/COVER%20Crescent%201%20rework.jpg' alt='Crescent University' />
            </div>
            <div className='flex justify-center items-center py-10 max-sm:py-5'>
                <FadeInSectionDownToUp>
                    <img className='max-sm:w-56' src='https://ik.imagekit.io/mhcockpit1/Crescent%20Landing/Crescent%20logo.png' alt='Crescent University' />
                </FadeInSectionDownToUp>
            </div>
            <FadeInSectionDownToUp>
                <div className='flex justify-center items-center pb-10 max-sm:py-5'>
                    <img className='w-1/2' src='https://ik.imagekit.io/mhcockpit1/Crescent%20Landing/Accreditation.jpg' alt='Crescent University' />
                </div>
            </FadeInSectionDownToUp>
            <div className='relative'>
                <div className=''>
                    <FadeInSectionDownToUp>
                        <img className='w-full object-cover' src='https://ik.imagekit.io/mhcockpit1/Crescent%20Landing/crescent%20building%20image.png' alt='Crescent University' />
                    </FadeInSectionDownToUp>
                </div>
                <div className='flex justify-center items-center absolute bottom-0 left-1/3 right-1/3'>
                    <FadeInSectionDownToUp>
                        <img className='w-96' src='https://ik.imagekit.io/mhcockpit1/Crescent%20Landing/benefits.png' alt='Crescent University' />
                    </FadeInSectionDownToUp>
                </div>
            </div>
            <div className='flex justify-center items-center py-10 max-sm:py-5'>
                <FadeInSectionDownToUp>
                    <img className='w-96 max-sm:w-56' src='https://ik.imagekit.io/mhcockpit1/Vels%20Landing/32%20LPA.png' alt='Crescent University' />
                </FadeInSectionDownToUp>
            </div>
            <FadeInSectionDownToUp>
                <div className='flex justify-center items-center py-5'>
                    <div>
                        <p>Placement Done by</p>
                    </div>
                    <div>
                        <img className='w-36' src='https://ik.imagekit.io/mhcockpit1/MHC%20LOGO%20Black.png' alt='Crescent Landing' />
                    </div>
                </div>
            </FadeInSectionDownToUp>
            <div>
                <Marquee speed={100} loop={1000} pauseOnHover>
                    {Placed1.map((placed, index) => (
                        <div key={index}>
                            <img src={placed.src} alt='Placed' className='w-36 max-sm:w-20 m-2' />
                        </div>
                    ))}
                </Marquee>
                <Marquee speed={100} direction='right' loop={1000} pauseOnHover>
                    {Placed2.map((placed, index) => (
                        <div key={index}>
                            <img src={placed.src} alt='Placed' className='w-36 max-sm:w-20 m-2' />
                        </div>
                    ))}
                </Marquee>
            </div>
            {/* <div className='flex justify-center items-center'>
                <div className='grid grid-cols-6 gap-20 xl:gap-20 sm:gap-10 max-sm:gap-5 px-36 max-sm:px-10 py-5'>
                    {CrescentLandingPlacedRow1.map((image, index) => (
                        <div key={index}>
                            <FadeInSectionDownToUp>
                                <img src={image.src} alt='Crescent University' className='w-36' />
                            </FadeInSectionDownToUp>
                        </div>
                    ))}
                </div>
            </div>
            <div className='flex justify-center items-center'>
                <div className='grid grid-cols-7 gap-20 xl:gap-20 sm:gap-10 max-sm:gap-5 px-20 max-sm:px-5 py-5'>
                    {CrescentLandingPlacedRow2.map((image, index) => (
                        <div key={index}>
                            <FadeInSectionDownToUp>
                                <img src={image.src} alt='Crescent University' className='w-36' />
                            </FadeInSectionDownToUp>
                        </div>
                    ))}
                </div>
            </div>
            <div className='flex justify-center items-center'>
                <div className='grid grid-cols-7 gap-20 xl:gap-20 sm:gap-10 max-sm:gap-5 px-20 max-sm:px-5 py-5'>
                    {CrescentLandingPlacedRow3.map((image, index) => (
                        <div key={index}>
                            <FadeInSectionDownToUp>
                                <img src={image.src} alt='Crescent University' className='w-36' />
                            </FadeInSectionDownToUp>
                        </div>
                    ))}
                </div>
            </div>
            <div className='flex justify-center items-center'>
                <div className='grid grid-cols-6 gap-20 xl:gap-20 sm:gap-10 max-sm:gap-5 px-36 max-sm:px-10 py-5'>
                    {CrescentLandingPlacedRow4.map((image, index) => (
                        <div key={index}>
                            <FadeInSectionDownToUp>
                                <img src={image.src} alt='Crescent University' className='w-36' />
                            </FadeInSectionDownToUp>
                        </div>
                    ))}
                </div>
            </div> */}
            <div className='flex justify-center items-center px-32 xl:px-32 sm:px-10 max-sm:px-5 py-20'>
                <div className='grid grid-cols-3 xl:grid-cols-3 sm:grid-cols-2 max-sm:grid-cols-1 gap-10 w-full'>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='text-xl font-semibold py-2'>Diverse Community</p>
                        </FadeInSectionDownToUp>
                        <div className='p-5 bg-[#06103d] text-white rounded-xl w-full h-full'>
                            <div className="text-lg text-justify">
                                <ul className='space-y-3'>
                                    <FadeInSectionDownToUp>
                                        <div className='flex'>
                                            <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                                Connect with students from all over India, sharing unique stories and experiences.
                                            </li>
                                        </div>
                                    </FadeInSectionDownToUp>
                                    <FadeInSectionDownToUp>
                                        <div className='flex'>
                                            <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                                Build friendships and exchange insights about the aviation industry with peers from diverse backgrounds.
                                            </li>
                                        </div>
                                    </FadeInSectionDownToUp>
                                    <FadeInSectionDownToUp>
                                        <div className='flex'>
                                            <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                                Engage in a lively setting that fosters open dialogue and broadens your perspective.
                                            </li>
                                        </div>
                                    </FadeInSectionDownToUp>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='text-xl font-semibold py-2'>Experienced Faculty</p>
                        </FadeInSectionDownToUp>
                        <div className='p-5 bg-[#06103d] text-white rounded-xl w-full h-full'>
                            <div className="text-lg text-justify">
                                <ul className='space-y-3'>
                                    <FadeInSectionDownToUp>
                                        <div className='flex'>
                                            <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                                Our instructors include Air Force veterans and pilots with over a decade of experience in the aviation industry.
                                            </li>
                                        </div>
                                    </FadeInSectionDownToUp>
                                    <FadeInSectionDownToUp>
                                        <div className='flex'>
                                            <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                                Faculty members bring valuable real-world insights, enhancing the learning experience for students.
                                            </li>
                                        </div>
                                    </FadeInSectionDownToUp>
                                    <FadeInSectionDownToUp>
                                        <div className='flex'>
                                            <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                                With years of experience, our faculty are dedicated to providing high-quality education and preparing students for successful careers in aviation.
                                            </li>
                                        </div>
                                    </FadeInSectionDownToUp>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='text-xl font-semibold py-2'>Internships</p>
                        </FadeInSectionDownToUp>
                        <div className='p-5 bg-[#06103d] text-white rounded-xl w-full h-full'>
                            <div className="text-lg text-justify">
                                <ul className='space-y-3'>
                                    <FadeInSectionDownToUp>
                                        <div className='flex'>
                                            <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                                Students will commence their internships in their second year of study.
                                            </li>
                                        </div>
                                    </FadeInSectionDownToUp>
                                    <FadeInSectionDownToUp>
                                        <div className='flex'>
                                            <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                                We have established a Memorandum of Understanding (MoU) with the Airports Authority of India (AAI) to facilitate internship opportunities.
                                            </li>
                                        </div>
                                    </FadeInSectionDownToUp>
                                    <FadeInSectionDownToUp>
                                        <div className='flex'>
                                            <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                                Internships will be available at international airports, airlines, and aircraft maintenance organizations.
                                            </li>
                                        </div>
                                    </FadeInSectionDownToUp>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='text-xl font-semibold py-2'>Cutting- Edge Curriculum</p>
                        </FadeInSectionDownToUp>
                        <div className='p-5 bg-[#06103d] text-white rounded-xl w-full h-full'>
                            <div className="text-lg text-justify">
                                <ul className='space-y-3'>
                                    <FadeInSectionDownToUp>
                                        <div className='flex'>
                                            <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                                Explore a diverse curriculum, including air traffic control, airport operations, and airline management.
                                            </li>
                                        </div>
                                    </FadeInSectionDownToUp>
                                    <FadeInSectionDownToUp>
                                        <div className='flex'>
                                            <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                                Develop practical skills through industry-focused projects.
                                            </li>
                                        </div>
                                    </FadeInSectionDownToUp>
                                    <FadeInSectionDownToUp>
                                        <div className='flex'>
                                            <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                                Prepare for top-tier roles in the aviation sector with essential skills and insights.
                                            </li>
                                        </div>
                                    </FadeInSectionDownToUp>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='text-xl font-semibold py-2'>Industrial Visits</p>
                        </FadeInSectionDownToUp>
                        <div className='p-5 bg-[#06103d] text-white rounded-xl w-full h-full'>
                            <div className="text-lg text-justify">
                                <ul className='space-y-3'>
                                    <FadeInSectionDownToUp>
                                        <div className='flex'>
                                            <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                                We help students understand the industry.
                                            </li>
                                        </div>
                                    </FadeInSectionDownToUp>
                                    <FadeInSectionDownToUp>
                                        <div className='flex'>
                                            <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                                We arrange visits to airports, airlines, maintenance organizations, and aerospace companies.
                                            </li>
                                        </div>
                                    </FadeInSectionDownToUp>
                                    <FadeInSectionDownToUp>
                                        <div className='flex'>
                                            <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                                Students see real-world operations and gain valuable knowledge.
                                            </li>
                                        </div>
                                    </FadeInSectionDownToUp>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='text-xl font-semibold py-2'>Higher Placement</p>
                        </FadeInSectionDownToUp>
                        <div className='p-5 bg-[#06103d] text-white rounded-xl w-full h-full'>
                            <div className="text-lg text-justify">
                                <ul className='space-y-3'>
                                    <FadeInSectionDownToUp>
                                        <div className='flex'>
                                            <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                                Crescent ensures graduates secure excellent aviation career opportunities.
                                            </li>
                                        </div>
                                    </FadeInSectionDownToUp>
                                    <FadeInSectionDownToUp>
                                        <div className='flex'>
                                            <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                                Success Rate: The institution has achieved a 100% success rate in placing students in the aviation industry.
                                            </li>
                                        </div>
                                    </FadeInSectionDownToUp>
                                    <FadeInSectionDownToUp>
                                        <div className='flex'>
                                            <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                                Highest Salary Package: Students have access to competitive salary packages, with the highest reaching ₹33 LPA.
                                            </li>
                                        </div>
                                    </FadeInSectionDownToUp>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex justify-center items-center px-32 xl:px-32 sm:px-10 max-sm:px-5 py-10'>
                <div className='grid grid-cols-2 xl:grid-cols-2 sm:grid-cols-2 max-sm:grid-cols-1 gap-10'>
                    <div>
                        <div className='p-5 bg-[#1e90ff] text-white rounded-xl w-full h-full'>
                            <FadeInSectionDownToUp>
                                <p className='text-xl font-semibold py-2'>Course Offered.</p>
                            </FadeInSectionDownToUp>
                            <div className="text-lg text-justify">
                                <ul className='space-y-3'>
                                    <FadeInSectionDownToUp>
                                        <div className='flex'>
                                            <li className=''>
                                                B.Sc. Aviation.
                                            </li>
                                        </div>
                                    </FadeInSectionDownToUp>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='w-full h-full'>
                        <div className='p-5 bg-[#1e90ff] text-white rounded-xl w-full h-full'>
                            <FadeInSectionDownToUp>
                                <p className='text-xl font-semibold py-2'>Eligibility.</p>
                            </FadeInSectionDownToUp>
                            <div className="text-lg text-justify">
                                <FadeInSectionDownToUp>
                                    <p>
                                        10th & 12th with Maths & Physics or its equivalent <br className='max-sm:hidden xl:block sm:hidden' />
                                        such as (3 years Engineering Diploma) from a <br className='max-sm:hidden xl:block sm:hidden' />
                                        Recognised Board.
                                    </p>
                                </FadeInSectionDownToUp>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <FadeInSectionDownToUp>
                    <p className='text-center text-3xl font-semibold py-5'>
                        Top Recruiters
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div>
                <Marquee speed={100} loop={1000}>
                    <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Air%20Asia%20logo.png' alt='Airline LOGO' />
                    <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Air_Canada_Logo.png' alt='Airline LOGO' />
                    <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Air_France-Logo.png' alt='Airline LOGO' />
                    <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Air_India_Express_Logo.png' alt='Airline LOGO' />
                    <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Air_New_Zealand_logo.png' alt='Airline LOGO' />
                    <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Air-Arabia-Logo.png' alt='Airline LOGO' />
                    <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/air-india-logo.png' alt='Airline LOGO' />
                    <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Air-Transat-Logo.png' alt='Airline LOGO' />
                    <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Akasa_Air_logo.svg.png' alt='Airline LOGO' />
                    <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Barrier%20Air.png' alt='Airline LOGO' />
                    <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/emirates-airlines.png' alt='Airline LOGO' />
                    <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Etihad-Airways-Logo.png' alt='Airline LOGO' />
                </Marquee>
                <Marquee speed={100} direction='right' loop={1000}>
                    <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/FlyDubai-Logo.png' alt='Airline LOGO' />
                    <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/indigo-vector-logo.png' alt='Airline LOGO' />
                    <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Japan%20Airlines.png' alt='Airline LOGO' />
                    <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Korean-Air-Logo.png' alt='Airline LOGO' />
                    <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Qantas-Logo.png' alt='Airline LOGO' />
                    <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Singapore%20Airlines.png' alt='Airline LOGO' />
                    <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/SpiceJet-Logo.png' alt='Airline LOGO' />
                    <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Swiss-International-Air-Lines-logo.png' alt='Airline LOGO' />
                    <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Turkish_Airlines_logo.png' alt='Airline LOGO' />
                    <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Vistara%20logo.png' alt='Airline LOGO' />
                    <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/WestJet-Logo.wine.png' alt='Airline LOGO' />
                    <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Wizz_Air_Logo.png' alt='Airline LOGO' />
                </Marquee>
            </div>
        </div>
    )
}
