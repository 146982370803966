import React from 'react'

export default function Footer() {

    const currentYear = new Date().getFullYear();

    return (
        <div>


            <footer class="bg-orange-50">

                <div className='py-10 px-52 max-sm:px-20'>
                    <div className='bg-gray-400 h-0.5'>
                        <hr />
                    </div>
                </div>

                <div className='grid grid-cols-4 max-sm:grid-cols-2 py-10 gap-4 px-10 max-sm:px-5'>
                    <div className='flex items-top justify-center max-sm:justify-start'>
                        <ul>
                            <li>
                                <a href='/training'>Training</a>
                            </li>
                            <li>
                                <a href='/training#pilot-training'>Pilot Training</a>
                            </li>
                            <li>
                                <a href='/private-pilot-license'>PPL</a>
                            </li>
                            <li>
                                <a href='/commercial-pilot-license'>CPL/IR/ME</a>
                            </li>
                            <li>
                                <a href='/typerating'>Type Rating</a>
                            </li>
                            <li>
                                <a href='/airline-transport-pilot-license'>ATPL - Ground School</a>
                            </li>
                            <li>
                                <a href='/dgca-license-conversion'>DGCA - License Conversion</a>
                            </li>
                            <li>
                                <a href='/dgca-medical-requirements'>DGCA-Medical Requirements</a>
                            </li>
                            <li>
                                <a href='/dgca-pilot-computer-number'>DGCA-Pilot Computer Number</a>
                            </li>
                            <li>
                                <a href='/training#degree-programs'>Degree Programs</a>
                            </li>
                            <li>
                                UG
                            </li>
                            <li>
                                <a href='/bba-aviation'>BBA Aviation Management</a>
                            </li>
                            <li>
                                <a href='/bsc-aviation'>BSc. Aviation</a>
                            </li>
                            <li>
                                <a href='/bsc-aeronautical-science'>BSc. Aeronautical Science</a>
                            </li>
                            {/* <li>
                                <a href='/btech-aerospace-engineering'>B.Tech Aerospace Engineering</a>
                            </li> */}
                            <li>
                                <a href='/bba-aviation-and-tourism-management'>BBA Aviation and Tourism Management</a>
                            </li>
                            <li>
                                <a href='/bba-logistics-and-supply-chain-management'>BBA Logistics and Supply Chain Management</a>
                            </li>
                            {/* <li>
                                <a href='/bsc-aircraft-maintenance-science'>BSc. Aircraft Maintenance Science</a>
                            </li> */}
                            <li>
                                PG
                            </li>
                            <li>
                                <a href='/mba-aviation-management'>MBA Aviation & Airport Management</a>
                            </li>
                            <li>
                                <a href='/mba-hr-marketing-and-finance'>MBA HR Marketing and Finance</a>
                            </li>
                            <li>
                                <a href='/mba-logistics-and-supply-chain-management'>MBA Logistics and Supply Chain Management</a>
                            </li>
                        </ul>
                    </div>
                    <div className='flex items-top justify-center max-sm:justify-start'>
                        <ul>
                            <li>
                                <a href='/training#diploma-programs'>Diploma Programs</a>
                            </li>
                            <li>
                                <a href='/cabincrew'>Cabin Crew Training</a>
                            </li>
                            <li>
                                <a href='/PG-Diploma-in-AME'>PG Diploma in AME</a>
                            </li>
                            <li>
                                <a href='/diploma-in-airport-management'>Airport Management</a>
                            </li>
                            <li>
                                <a href='https://mhintellect.com'>MH Intellect</a>
                            </li>
                            <li>
                                Consulting
                            </li>
                            <li>
                                <a href='/aircraft-leasing-&-financing'>Aircraft Leasing & Financing</a>
                            </li>
                            <li>
                                <a href='/labestablishment'>Lab Establishment</a>
                            </li>
                            <li>
                                Innovation
                            </li>
                            <li>
                                <a href='/fixedwing'>Fixed Wing</a>
                            </li>
                            <li>
                                <a href='/flight-simulator'>Flight Simulator</a>
                            </li>
                            <li>
                                <a href='https://mhagro.mhglobal.info/' target='blank'>Agriculture</a>
                            </li>
                        </ul>
                    </div>
                    <div className='flex items-top justify-center max-sm:justify-start'>
                        <ul>
                            <li>
                                <a href='/colleges'>Colleges</a>
                            </li>
                            <li>
                                Careers
                            </li>
                            <li>
                                <a href='/pilotgroundinstructor'>Pilot</a>
                            </li>
                            <li>
                                <a href='/ameinstructor'>AME</a>
                            </li>
                            <li>
                                <a href='/cabincrewinstructor'>Cabin Crew</a>
                            </li>
                            <li>
                                <a href='/airportgroundoperationspersonnel'>Ground Staff</a>
                            </li>
                            <li>
                                <a href='/flightdispatch'>Flight Dispatch</a>
                            </li>
                            <li>
                                <a href='/it'>IT</a>
                            </li>
                            <li>
                                <a href='/dronepilots'>Drones</a>
                            </li>
                            <li>
                                <a href='/bdm'>BDM</a>
                            </li>
                            <li>
                                <a href='/finance'>Finance</a>
                            </li>
                            <li>
                                <a href='/media'>Media</a>
                            </li>
                        </ul>
                    </div>
                    <div className='flex items-top justify-center max-sm:justify-start'>
                        <ul>
                            <li>
                                <a href='/company'>Company</a>
                            </li>
                            <li>
                                <a href='/ourgovernance'>Who We Are</a>
                            </li>
                            <li>
                                <a href='/our-values'>What We Do</a>
                            </li>
                            <li>
                                <a href='/our-presence'>Our Presence</a>
                            </li>
                            <div className='grid grid-cols-2'>
                                <a href='/The-Impact-of-Artificial-Intelligence-on-Aviation'><img className='w-32 px-1 py-1 max-sm:px-2' src='https://ik.imagekit.io/mhcockpit1/blogs/AI.webp' alt='Blog' /></a>
                                <a href='/Exploring-Career-Opportunities-in-Aviation'><img className='w-32 px-1 py-1 max-sm:px-2' src='https://ik.imagekit.io/mhcockpit1/blogs/Career%20Opportunites.webp' alt='Blog' /></a>
                            </div>
                            <div className='grid grid-cols-2'>
                                <a href='/A-Day-in-the-Life-of-an-Aviation-Student'><img className='w-32 px-1 py-1 max-sm:px-2' src='https://ik.imagekit.io/mhcockpit1/blogs/Day%20in%20a%20Life%20of%20a%20Aviation%20Students.webp' alt='Blog' /></a>
                                <a href='/Deciding-Between-Studying-CPL-Abroad-or-in-India'><img className='w-32 px-1 py-1 max-sm:px-2' src='https://ik.imagekit.io/mhcockpit1/blogs/Desiding%20between%20studies%20in%20abroad.webp' alt='Blog' /></a>
                            </div>
                        </ul>
                    </div>
                </div>

                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.305692585349!2d80.16034307512247!3d12.952280487361296!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52675aeb55363f%3A0x491352dd8672f0dd!2sMH%20Cockpit%20-%20Aviation%20Academy!5e0!3m2!1sen!2sin!4v1712053178519!5m2!1sen!2sin" width="100%" height="300" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title='MH Cockpit'></iframe>

                <div className='bg-blue-900 grid xl:grid-cols-5 lg:grid-cols-5 max-sm:grid-cols-1 max-sm:space-y-2 md:grid-cols-2 md:space-y-2 py-2 px-10 text-white text-sm'>
                    <div className='flex justify-center items-center -mr-6'>
                        <p>Copyright © {currentYear} MH Cockpit. All rights reserved.</p>
                    </div>
                    <div className='flex justify-center items-center'>
                        <a href='mailto:info@mhcockpit.com'>info@mhcockpit.com</a>
                    </div>
                    <div className='flex justify-center items-center'>
                        <p>Contact: 7305952831 / 9884807480.</p>
                    </div>
                    <div className='flex justify-center items-center text-blue-600 underline underline-offset-1'>
                        <a href='/privacy-policy'>Privacy Policy.</a>
                    </div>
                    <div className='flex justify-center items-center text-blue-600 underline underline-offset-1'>
                        <a href='/terms-and-conditions'>Terms and conditions.</a>
                    </div>
                </div>


                {/* <div class="mx-auto w-full max-w-screen-xl">
                    <div class="grid grid-cols-2 gap-8 px-4 py-6 lg:py-8 md:grid-cols-4">
                        <div>
                            <h2 class="mb-6 text-sm font-semibold text-gray-500 uppercase">Company</h2>
                            <ul class="text-gray-500 font-medium">
                                <li class="mb-4">
                                    <a href="#" class=" hover:underline">About</a>
                                </li>
                                <li class="mb-4">
                                    <a href="#" class="hover:underline">Careers</a>
                                </li>
                                <li class="mb-4">
                                    <a href="#" class="hover:underline">Brand Center</a>
                                </li>
                                <li class="mb-4">
                                    <a href="#" class="hover:underline">Blog</a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2 class="mb-6 text-sm font-semibold text-gray-500 uppercase">Help center</h2>
                            <ul class="text-gray-500 font-medium">
                                <li class="mb-4">
                                    <a href="#" class="hover:underline">Discord Server</a>
                                </li>
                                <li class="mb-4">
                                    <a href="#" class="hover:underline">Twitter</a>
                                </li>
                                <li class="mb-4">
                                    <a href="#" class="hover:underline">Facebook</a>
                                </li>
                                <li class="mb-4">
                                    <a href="#" class="hover:underline">Contact Us</a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2 class="mb-6 text-sm font-semibold text-gray-500 uppercase">Legal</h2>
                            <ul class="text-gray-500 font-medium">
                                <li class="mb-4">
                                    <a href="#" class="hover:underline">Privacy Policy</a>
                                </li>
                                <li class="mb-4">
                                    <a href="#" class="hover:underline">Licensing</a>
                                </li>
                                <li class="mb-4">
                                    <a href="#" class="hover:underline">Terms &amp; Conditions</a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2 class="mb-6 text-sm font-semibold text-gray-500 uppercase">Download</h2>
                            <ul class="text-gray-500 font-medium">
                                <li class="mb-4">
                                    <a href="#" class="hover:underline">iOS</a>
                                </li>
                                <li class="mb-4">
                                    <a href="#" class="hover:underline">Android</a>
                                </li>
                                <li class="mb-4">
                                    <a href="#" class="hover:underline">Windows</a>
                                </li>
                                <li class="mb-4">
                                    <a href="#" class="hover:underline">MacOS</a>
                                </li>
                            </ul>
                            <div>
                                <div className='grid grid-cols-2'>
                                    <img className='w-32 py-2 max-sm:px-2' src='https://ik.imagekit.io/mhcockpit1/blogs/AI.webp' alt='Blog' />
                                    <img className='w-32 py-2 max-sm:px-2' src='https://ik.imagekit.io/mhcockpit1/blogs/Career%20Opportunites.webp' alt='Blog' />
                                </div>
                                <div className='grid grid-cols-2'>
                                    <img className='w-32 py-2 max-sm:px-2' src='https://ik.imagekit.io/mhcockpit1/blogs/Day%20in%20a%20Life%20of%20a%20Aviation%20Students.webp' alt='Blog' />
                                    <img className='w-32 py-2 max-sm:px-2' src='https://ik.imagekit.io/mhcockpit1/blogs/Desiding%20between%20studies%20in%20abroad.webp' alt='Blog' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="px-4 py-6 md:flex md:items-center md:justify-between">
                        <span class="text-sm text-black sm:text-center">© 2023 <a href="https://flowbite.com/">Flowbite™</a>. All Rights Reserved.
                        </span>
                        <div class="flex mt-4 sm:justify-center md:mt-0 space-x-5 rtl:space-x-reverse">
                            <a href="#" class="text-black hover:text-gray-900 dark:hover:text-white">
                                <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 8 19">
                                    <path fill-rule="evenodd" d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z" clip-rule="evenodd" />
                                </svg>
                                <span class="sr-only">Facebook page</span>
                            </a>
                            <a href="#" class="text-black hover:text-gray-900 dark:hover:text-white">
                                <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 21 16">
                                    <path d="M16.942 1.556a16.3 16.3 0 0 0-4.126-1.3 12.04 12.04 0 0 0-.529 1.1 15.175 15.175 0 0 0-4.573 0 11.585 11.585 0 0 0-.535-1.1 16.274 16.274 0 0 0-4.129 1.3A17.392 17.392 0 0 0 .182 13.218a15.785 15.785 0 0 0 4.963 2.521c.41-.564.773-1.16 1.084-1.785a10.63 10.63 0 0 1-1.706-.83c.143-.106.283-.217.418-.33a11.664 11.664 0 0 0 10.118 0c.137.113.277.224.418.33-.544.328-1.116.606-1.71.832a12.52 12.52 0 0 0 1.084 1.785 16.46 16.46 0 0 0 5.064-2.595 17.286 17.286 0 0 0-2.973-11.59ZM6.678 10.813a1.941 1.941 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.919 1.919 0 0 1 1.8 2.047 1.93 1.93 0 0 1-1.8 2.045Zm6.644 0a1.94 1.94 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.918 1.918 0 0 1 1.8 2.047 1.93 1.93 0 0 1-1.8 2.045Z" />
                                </svg>
                                <span class="sr-only">Discord community</span>
                            </a>
                            <a href="#" class="text-black hover:text-gray-900 dark:hover:text-white">
                                <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 17">
                                    <path fill-rule="evenodd" d="M20 1.892a8.178 8.178 0 0 1-2.355.635 4.074 4.074 0 0 0 1.8-2.235 8.344 8.344 0 0 1-2.605.98A4.13 4.13 0 0 0 13.85 0a4.068 4.068 0 0 0-4.1 4.038 4 4 0 0 0 .105.919A11.705 11.705 0 0 1 1.4.734a4.006 4.006 0 0 0 1.268 5.392 4.165 4.165 0 0 1-1.859-.5v.05A4.057 4.057 0 0 0 4.1 9.635a4.19 4.19 0 0 1-1.856.07 4.108 4.108 0 0 0 3.831 2.807A8.36 8.36 0 0 1 0 14.184 11.732 11.732 0 0 0 6.291 16 11.502 11.502 0 0 0 17.964 4.5c0-.177 0-.35-.012-.523A8.143 8.143 0 0 0 20 1.892Z" clip-rule="evenodd" />
                                </svg>
                                <span class="sr-only">Twitter page</span>
                            </a>
                            <a href="#" class="text-black hover:text-gray-900 dark:hover:text-white">
                                <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path fill-rule="evenodd" d="M10 .333A9.911 9.911 0 0 0 6.866 19.65c.5.092.678-.215.678-.477 0-.237-.01-1.017-.014-1.845-2.757.6-3.338-1.169-3.338-1.169a2.627 2.627 0 0 0-1.1-1.451c-.9-.615.07-.6.07-.6a2.084 2.084 0 0 1 1.518 1.021 2.11 2.11 0 0 0 2.884.823c.044-.503.268-.973.63-1.325-2.2-.25-4.516-1.1-4.516-4.9A3.832 3.832 0 0 1 4.7 7.068a3.56 3.56 0 0 1 .095-2.623s.832-.266 2.726 1.016a9.409 9.409 0 0 1 4.962 0c1.89-1.282 2.717-1.016 2.717-1.016.366.83.402 1.768.1 2.623a3.827 3.827 0 0 1 1.02 2.659c0 3.807-2.319 4.644-4.525 4.889a2.366 2.366 0 0 1 .673 1.834c0 1.326-.012 2.394-.012 2.72 0 .263.18.572.681.475A9.911 9.911 0 0 0 10 .333Z" clip-rule="evenodd" />
                                </svg>
                                <span class="sr-only">GitHub account</span>
                            </a>
                            <a href="#" class="text-black hover:text-gray-900 dark:hover:text-white">
                                <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path fill-rule="evenodd" d="M10 0a10 10 0 1 0 10 10A10.009 10.009 0 0 0 10 0Zm6.613 4.614a8.523 8.523 0 0 1 1.93 5.32 20.094 20.094 0 0 0-5.949-.274c-.059-.149-.122-.292-.184-.441a23.879 23.879 0 0 0-.566-1.239 11.41 11.41 0 0 0 4.769-3.366ZM8 1.707a8.821 8.821 0 0 1 2-.238 8.5 8.5 0 0 1 5.664 2.152 9.608 9.608 0 0 1-4.476 3.087A45.758 45.758 0 0 0 8 1.707ZM1.642 8.262a8.57 8.57 0 0 1 4.73-5.981A53.998 53.998 0 0 1 9.54 7.222a32.078 32.078 0 0 1-7.9 1.04h.002Zm2.01 7.46a8.51 8.51 0 0 1-2.2-5.707v-.262a31.64 31.64 0 0 0 8.777-1.219c.243.477.477.964.692 1.449-.114.032-.227.067-.336.1a13.569 13.569 0 0 0-6.942 5.636l.009.003ZM10 18.556a8.508 8.508 0 0 1-5.243-1.8 11.717 11.717 0 0 1 6.7-5.332.509.509 0 0 1 .055-.02 35.65 35.65 0 0 1 1.819 6.476 8.476 8.476 0 0 1-3.331.676Zm4.772-1.462A37.232 37.232 0 0 0 13.113 11a12.513 12.513 0 0 1 5.321.364 8.56 8.56 0 0 1-3.66 5.73h-.002Z" clip-rule="evenodd" />
                                </svg>
                                <span class="sr-only">Dribbble account</span>
                            </a>
                        </div>
                    </div>
                </div> */}
            </footer>

        </div>
    )
}
