import React from 'react';
import FadeInSectionDownToUp from '../../Components/FadeAnimations/FadeInSectionDownToUp';

function DronePilot() {

    return (
        <div>
            <div className="relative text-justify">
                <div className="inset-0 overflow-hidden">
                    <img
                        className='w-full h-full object-cover animate-zoomOut'
                        src="https://ik.imagekit.io/mhcockpit1/Drone%20Pilots%20-%20Image.webp"
                        alt="DGCA - License conversion"
                    />
                </div>
                <div className="relative py-10">
                    <div className='pl-40 xl:pl-40 sm:pl-20 max-sm:pl-10 text-4xl max-sm:text-2xl font-semibold tracking-tighter py-10'>
                        <FadeInSectionDownToUp>
                            <h1>Drone Pilot & Engineer.</h1>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-40 xl:px-40 sm:px-20 max-sm:px-10 py-5 text-xl'>
                        <FadeInSectionDownToUp>
                            <p>
                                We are hiring Drone Engineers to take part in our Drone operations. Our drone engineering team develops educational based drones. You will work in the team of engineers and hence must possess the ability to perform well in a team. In other words, your ultimate goal will be to create scalable and fully efficient drones for the students club activities.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-40 xl:px-40 sm:px-20 max-sm:px-10 py-5 text-3xl max-sm:text-xl font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                Requirements.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg px-40 xl:px-40 sm:px-20 max-sm:px-10 mb-5 text-justify">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        At least a Bachelor’s degree in Engineering.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        2 years working experience in drone industry.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Open to travel.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Experience in flight testing and operating/piloting UAS and/or RC aircraft (both fixed-wing and multi-rotor systems).
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Experience in using flight-planning and ground control station software.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Well versed with existing open source flight controllers.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Ability to work hands-on with electro-mechanical systems including assembly, disassembly, testing, troubleshooting.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Good verbal and written communication skills.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        An analytical mind in addition to a problem-solving attitude.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Ability to take quick decisions while working in a fast-paced environment.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Willing to relocate.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                    <div className='px-40 xl:px-40 sm:px-20 max-sm:px-10 py-5 text-3xl max-sm:text-xl font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                Integrity and interpersonal skills Responsibilities.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg px-40 xl:px-40 sm:px-20 max-sm:px-10 mb-5 text-justify">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Plan and execute flight test plans for new software features, electronics, sensors, and payloads • Perform hands-on mechanical and electrical integration of new hardware components on the internal fleet of test vehicles for R&D and testing.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Troubleshoot and debug any components of a drone in the office or in the field.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Maintenance of vehicles – keep the fleet ready for flight tests.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Learn about the domain and competitors to propose new drone applications, as well as, improvements in existing applications.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Flight testing and operating/piloting UAS and/or RC aircraft (both fixed-wing and multirotor systems).
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Learn about the domain and competitors to propose new drone applications, as well as, improvements in existing applications.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                    <div className='flex justify-center items-center'>
                        <FadeInSectionDownToUp>
                            <button
                                className='px-5 py-1 bg-slate-600 text-white font-semibold rounded-lg animate-pulse hover:scale-105 transition-transform duration-700 ease-in-out'
                            >
                                <a href='https://forms.gle/BQKYYuKCrrqaae2KA' target='blank'>
                                    Apply Now
                                </a>
                            </button>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='flex justify-center items-center pt-2'>
                        <FadeInSectionDownToUp>
                            <a href='mailto:careers@mhcockpit.com' className='font-semibold text-xl'>careers@mhcockpit.com</a>
                        </FadeInSectionDownToUp>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DronePilot;
