export const PilotTraining = [

    {
        src: "https://ik.imagekit.io/mhcockpit1/Training/PPL.webp",
        alt: "PPL",
        title: "Private Pilot License",
        link: "/private-pilot-license",
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/Training/CPL.webp",
        alt: "CPL",
        title: "CPL/IR/ME",
        link: "/commercial-pilot-license",
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/Training/Type%20Rating.webp",
        alt: "Type Rating",
        title: "Type Rating",
        link: "/typerating",
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/Training/ATPL%20GC.webp",
        alt: "ATPL",
        title: "ATPL - Ground Classes",
        link: "/airline-transport-pilot-license",
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/Training/license%20conversion.webp",
        alt: "DGCA LC",
        title: "DGCA - License Conversion",
        link: "/dgca-license-conversion",
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/Training/medical%20requirement%20dgca.webp",
        alt: "DGCA MR",
        title: "DGCA Medical Requirements",
        link: "/dgca-medical-requirements",
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/Training/online%20test%20DGCA.webp",
        alt: "DGCA PCN",
        title: "DGCA - Pilot Computer Number",
        link: "/dgca-pilot-computer-number",
    },

];

export const DegreePrograms = [

    {
        src: "https://ik.imagekit.io/mhcockpit1/Training/bba%20Aviaiton.webp",
        alt: "BBA Aviation Management",
        title: "BBA Aviation Management",
        link: "/bba-aviation",
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/Training/bsc%20aviation.webp",
        alt: "BSc. Aviation",
        title: "BSc. Aviation",
        link: "/bsc-aviation",
    },
    // {
    //     src: "https://ik.imagekit.io/mhcockpit1/Training/Aircraft%20Maintenance.webp",
    //     alt: "B.Sc Aircraft Maintenance Science",
    //     title: "B.Sc Aircraft Maintenance Science",
    //     link: "/bsc-aircraft-maintenance-science",
    // },
    {
        src: "https://ik.imagekit.io/mhcockpit1/Training/B.Sc%20Aeronautical%20Science.webp",
        alt: "B.Sc- Aeronautical Science",
        title: "B.Sc- Aeronautical Science",
        link: "/bsc-aeronautical-science",
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/Training/mba%201.webp",
        alt: "MBA- Aviation & Airport Management",
        title: "MBA- Aviation & Airport Management",
        link: "/mba-aviation-management",
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/Training/MBA.webp",
        alt: "MBA HR Marketing and Finance",
        title: "MBA HR Marketing and Finance",
        link: "/mba-hr-marketing-and-finance",
    },
    // {
    //     src: "https://ik.imagekit.io/mhcockpit1/Training/Aircraft%20Maintenance.webp",
    //     alt: "B.Tech Aerospace Engineering",
    //     title: "B.Tech Aerospace Engineering",
    //     link: "/btech-aerospace-engineering",
    // },
    {
        src: "https://ik.imagekit.io/mhcockpit1/Training/Logistics%20and%20Supply%20Chain%20Management.webp",
        alt: "BBA Logistics and Supply Chain Management",
        title: "BBA Logistics and Supply Chain Management",
        link: "/bba-logistics-and-supply-chain-management",
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/Training/Aviation%20and%20Tourism%20Management.webp",
        alt: "BBA Aviation and Tourism Management",
        title: "BBA Aviation and Tourism Management",
        link: "/bba-aviation-and-tourism-management",
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/Training/Logistics%20and%20Supply%20Chain%20Management.webp",
        alt: "MBA Logistics and Supply Chain Management",
        title: "MBA Logistics and Supply Chain Management",
        link: "/mba-logistics-and-supply-chain-management",
    },

];

export const DiplomaPrograms = [

    {
        src: "https://ik.imagekit.io/mhcockpit1/Training/Diploma%20Cabin%20Crew.jpg",
        alt: "Cabin Crew",
        title: "Cabin Crew",
        link: "/cabincrew",
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/Training/Aircraft%20Maintenance%20diploma.webp",
        alt: "PG Diploma in AME",
        title: "PG Diploma in AME",
        link: "/PG-Diploma-in-AME",
    },
    {
        src: "https://ik.imagekit.io/mhcockpit1/Training/airport%20operation.webp",
        alt: "Airport/ Airline Operations (Ground/Flight Ops)",
        title: "Airport/ Airline Operations (Ground/Flight Ops)",
        link: "/diploma-in-airport-management",
    },

]

export const MHIntellect = [

    {
        src: "https://ik.imagekit.io/mhcockpit1/Training/MHA&I.webp",
        alt: "MH Intellect",
        title: "MH Intellect",
        link: "https://mhintellect.com/",
    }

]