// import React, { useState, useEffect } from 'react';

// export default function LMS() {
//     const lmsimgclick = () => {
//         alert('Learning Management System is under development!');
//     }

//     const [scrollPosition, setScrollPosition] = useState(0);

//     useEffect(() => {
//         const handleScroll = () => {
//             setScrollPosition(window.scrollY);
//         };

//         window.addEventListener('scroll', handleScroll);

//         return () => {
//             window.removeEventListener('scroll', handleScroll);
//         };
//     }, []);

//     const imageScale = 1 + scrollPosition * 0.001; // Adjust scale factor as needed
//     const fadeOpacity = Math.max(0, 1 - (scrollPosition / 500)); // Adjust fade-out threshold as needed

//     return (
//         <div className="relative overflow-hidden">
//             <div className="absolute inset-0 flex items-center justify-center z-10">
//                 <div className="text-center">
//                     <h1 className="text-4xl font-bold mb-4 opacity-0 transition-opacity duration-1000" style={{ opacity: scrollPosition < 500 ? 1 : 0 }}>
//                         Effortless <span className='text-blue-500'>Learning,</span>
//                     </h1>
//                     <h1 className="text-3xl font-semibold mb-4 opacity-0 transition-opacity duration-1000" style={{ opacity: scrollPosition < 500 ? 1 : 0 }}>
//                         Endless Possibilities
//                     </h1>
//                     <h5 className="text-xl font-light opacity-0 transition-opacity duration-1000" style={{ opacity: scrollPosition < 500 ? 1 : 0 }}>
//                         Experience Our LMS App
//                     </h5>
//                 </div>
//             </div>
//             <div className="flex justify-center">
//                 <a href="#" onClick={lmsimgclick}>
//                     <img
//                         className="transition-transform duration-1000"
//                         src={'https://ik.imagekit.io/mhcockpit1/LMS%20SOFTWARE%20For%20Developing.png?updatedAt=1712039494044'}
//                         alt="LMS"
//                         style={{
//                             transform: `scale(${imageScale})`,
//                             opacity: scrollPosition >= 500 ? fadeOpacity : 1,
//                             transition: 'transform 1s, opacity 1s'
//                         }}
//                     />
//                 </a>
//             </div>
//         </div>
//     )
// }


// import React, { useState, useEffect, useRef } from 'react';

// export default function LMS() {
//     const lmsimgclick = () => {
//         alert('Learning Management System is under development!');
//     }

//     const [scrollPosition, setScrollPosition] = useState(0);
//     const sectionRef = useRef(null);

//     useEffect(() => {
//         const handleScroll = () => {
//             if (sectionRef.current) {
//                 const sectionTop = sectionRef.current.getBoundingClientRect().top;
//                 const viewportHeight = window.innerHeight;

//                 // Calculate scroll position relative to the section
//                 const relativeScrollPosition = viewportHeight - sectionTop;

//                 // Ensure the scroll position is non-negative
//                 setScrollPosition(Math.max(relativeScrollPosition, 0));
//             }
//         };

//         window.addEventListener('scroll', handleScroll);
//         handleScroll(); // Initial check

//         return () => {
//             window.removeEventListener('scroll', handleScroll);
//         };
//     }, []);

//     const imageScale = 1 - scrollPosition * 0.001; // Adjust scale factor as needed
//     const fadeOpacity = Math.max(0, 1 - (scrollPosition / 500)); // Adjust fade-out threshold as needed

//     return (
//         <div>
//             <div ref={sectionRef} className=" overflow-hidden">
//                 <div className=" inset-0 flex items-center justify-center z-10 sticky">
//                     <div className="text-center">
//                         <h1 className="text-4xl font-bold mb-4 opacity-0 transition-opacity duration-1000" style={{ opacity: scrollPosition < 500 ? 1 : 0 }}>
//                             Effortless <span className='text-blue-500'>Learning,</span>
//                         </h1>
//                         <h1 className="text-3xl font-semibold mb-4 opacity-0 transition-opacity duration-1000" style={{ opacity: scrollPosition < 500 ? 1 : 0 }}>
//                             Endless Possibilities
//                         </h1>
//                         <h5 className="text-xl font-light opacity-0 transition-opacity duration-1000" style={{ opacity: scrollPosition < 500 ? 1 : 0 }}>
//                             Experience Our LMS App
//                         </h5>
//                     </div>
//                 </div>
//                 <div className="flex justify-center">
//                     <a href="#" onClick={lmsimgclick}>
//                         <img
//                             className="transition-transform duration-1000"
//                             src={'https://ik.imagekit.io/mhcockpit1/LMS%20SOFTWARE%20For%20Developing.png?updatedAt=1712039494044'}
//                             alt="LMS"
//                             style={{
//                                 transform: `scale(${imageScale})`,
//                                 // opacity: scrollPosition >= 500 ? fadeOpacity : 1,
//                                 transition: 'transform 1s, opacity 1s'
//                             }}
//                         />
//                     </a>
//                 </div>
//             </div>
//         </div>
//     )
// }


// import { useScroll, useTransform, motion } from 'framer-motion';
// import { LmsCourses } from './LmsCourses';

// export const LMS = () => {
//     return (
//         <div className="flex flex-col bg-white overflow-hidden">
//             <ScrollCore />
//         </div>
//     );
// };
// export const ScrollCore = () => {
//     //   const { scrollYProgress } = useScroll();
//     const { scrollYProgress } = useScroll({
//         target: null, // Set the target to `null` for window scroll
//         start: "0px 0px", // Define the start point (top of the viewport)
//         end: "200px 0px", // Define the end point (200px from the top of the viewport)
//     });

//     const rotate = useTransform(scrollYProgress, [0, 1], [20, 0]);
//     const scale = useTransform(scrollYProgress, [0, 1], [1.05, 1]);
//     const translate = useTransform(scrollYProgress, [0, 1], [0, -100]);

//     return (
//         <div className="h-[90vh] transform scale-[0.8] p-10 flex items-center justify-center relative">
//             <div
//                 className="py-40 w-full relative"
//                 style={{
//                     perspective: '400px',
//                 }}
//             >
//                 <Header translate={translate} />
//                 <Card rotate={rotate} translate={translate} scale={scale} />
//             </div>
//         </div>
//     );
// };

// export const Header = ({ translate }) => {
//     return (
//         <motion.div
//             style={{
//                 translateY: translate,
//             }}
//             className="div max-w-5xl mx-auto text-center"
//         >
//             <h1 className="text-5xl max-sm:text-3xl font-semibold">
//                 Effortless <span className='text-indigo-700'>Learning,</span><br />
//                 Endless Possibilities<br />
//                 <span className="text-2xl">
//                     Experience Our LMS App
//                 </span>
//             </h1>
//         </motion.div>
//     );
// };

// export const Card = ({
//     rotate,
//     scale,
//     translate,
// }) => {
//     return (
//         <motion.div
//             style={{
//                 rotateX: rotate, // rotate in X-axis
//                 scale,
//                 boxShadow:
//                     '0 0 #0000004d, 0 9px 20px #0000004a, 0 37px 37px #00000042, 0 84px 50px #00000026, 0 149px 60px #0000000a, 0 233px 65px #00000003',
//             }}
//             className="max-w-5xl -mt-12 mx-auto h-[30rem] md:h-[40rem] w-full border-4 border-[#6C6C6C] p-6 bg-[#222222] rounded-[30px] shadow-2xl"
//         >
//             <div className="bg-gray-100 h-full w-full rounded-2xl grid grid-cols-2 md:grid-cols-4 gap-4 overflow-scroll p-4">
//                 {LmsCourses.map((course, idx) => (
//                     <motion.div
//                         key={`course-${idx}`}
//                         className="bg-white rounded-md cursor-pointer relative"
//                         style={{ translateY: translate }}
//                         whileHover={{
//                             boxShadow:
//                                 '0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)',
//                         }}
//                     >
//                         <div className="absolute top-2 right-2 rounded-full text-xs font-bold bg-white px-2 py-1">
//                             {course.badge}
//                         </div>
//                         <img
//                             src={course.image}
//                             className="rounded-tr-md rounded-tl-md text-sm "
//                         />
//                         <div className="p-4">
//                             <h1 className="font-semibold text-sm ">{course.name}</h1>
//                             <h2 className=" text-gray-500 text-xs ">{course.designation}</h2>
//                         </div>
//                     </motion.div>
//                 ))}
//             </div>
//         </motion.div>
//     );
// };



import { useScroll, useTransform, motion } from 'framer-motion';
import { LmsCourses } from './LmsCourses';
import FadeInSectionDownToUp from '../FadeAnimations/FadeInSectionDownToUp';

export const LMS = () => {
    return (
        <div className="flex flex-col bg-white">
            <ScrollCore />
        </div>
    );
};

export const ScrollCore = () => {
    const { scrollYProgress } = useScroll({
        target: null, // Set the target to `null` for window scroll
        start: "0px 0px", // Define the start point (top of the viewport)
        end: "200px 0px", // Define the end point (200px from the top of the viewport)
    });

    const rotate = useTransform(scrollYProgress, [0, 1], [20, 0]);
    const scale = useTransform(scrollYProgress, [0, 1], [1.05, 1]);
    const translate = useTransform(scrollYProgress, [0, 1], [0, -100]);

    return (
        <div className="h-[90vh] xl:h-[100vh] max-xl:h-[100vh] 2xl:h-[100vh] max-2xl:h-[100vh] md:h-[100vh] max-sm:h-[80vh] transform scale-[0.8] p-10 flex items-center justify-center relative">
            <div
                className="py-40 w-full relative perspective-max-sm sm:perspective-sm md:perspective-md lg:perspective-lg xl:perspective-xl"
            >
                <Header translate={translate} />
                <Card rotate={rotate} translate={translate} scale={scale} />
            </div>
        </div>
    );
};

export const Header = ({ translate }) => {
    return (
        <motion.div
            style={{
                translateY: translate,
            }}
            className="div max-w-5xl mx-auto text-center"
        >
            <h1 className="text-5xl max-sm:text-3xl font-semibold">
                Effortless <span className='text-indigo-700'>Learning,</span><br />
                Endless Possibilities<br />
                <span className="text-2xl">
                    Experience Our LMS App
                </span>
            </h1>
        </motion.div>
    );
};

export const Card = ({
    rotate,
    scale,
    translate,
}) => {
    return (
        <motion.div
            style={{
                rotateX: rotate, // rotate in X-axis
                scale,
                boxShadow:
                    '0 0 #0000004d, 0 9px 20px #0000004a, 0 37px 37px #00000042, 0 84px 50px #00000026, 0 149px 60px #0000000a, 0 233px 65px #00000003',
            }}
            className="max-w-5xl -mt-12 mx-auto h-[30rem] md:h-[40rem] w-full border-4 border-[#6C6C6C] p-6 bg-[#222222] rounded-[30px] shadow-2xl"
        >
            <div className="bg-gray-100 h-full w-full rounded-2xl grid grid-cols-2 md:grid-cols-4 gap-4 overflow-y-scroll p-4 pt-20">
                {LmsCourses.map((course, idx) => (
                    <FadeInSectionDownToUp>
                        <motion.div
                            key={`course-${idx}`}
                            className="bg-white rounded-md cursor-pointer relative h-full"
                            style={{ translateY: translate }}
                            whileHover={{
                                boxShadow:
                                    '0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)',
                            }}
                        >
                            <a href={course.link} target={course.target}>
                                {/* <div className="absolute top-2 right-2 rounded-full text-xs font-bold bg-white px-2 py-1">
                                {course.badge}
                            </div> */}
                                <img
                                    src={course.image}
                                    className="rounded-tr-md rounded-tl-md text-sm "
                                />
                                <div className="p-4">
                                    <h1 className="font-semibold text-sm ">{course.name}</h1>
                                    {/* <h2 className=" text-gray-500 text-xs ">{course.designation}</h2> */}
                                </div>
                            </a>
                        </motion.div>
                    </FadeInSectionDownToUp>
                ))}
            </div>
        </motion.div>
    );
};
