import React from 'react'
import FadeInSectionDownToUp from '../../../Components/FadeAnimations/FadeInSectionDownToUp'
import Marquee from 'react-fast-marquee'
import AliceCarousel from 'react-alice-carousel'

export default function VelsLanding() {

    // const VelsLandingPlacedRow1 = [

    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/Swarna.png"
    //     },
    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/Joshua.png"
    //     },
    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/sanjay.png"
    //     },
    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/yahkoob.png"
    //     },
    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/Hariharan.png"
    //     },
    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/Merlin.png"
    //     }

    // ]

    // const VelsLandingPlacedRow2 = [

    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/Priyanka.png"
    //     },
    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/sneha.png"
    //     },
    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/Nivetha.png"
    //     },
    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/adil.png"
    //     },
    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/Ragapriya.png"
    //     },
    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/Prasanth.png"
    //     },
    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/Raqueeb.png"
    //     },

    // ]

    // const VelsLandingPlacedRow3 = [

    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/Henry.png"
    //     },
    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/Irshath.png"
    //     },
    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/Sneha-g.png"
    //     },
    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/Shabin.png"
    //     },
    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/Alsherif.png"
    //     },
    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/Akilan.png"
    //     },
    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/silvio.png"
    //     },

    // ]
    // const VelsLandingPlacedRow4 = [

    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/Gurukaviya.png"
    //     },
    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/Saran.png"
    //     },
    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/vlog-viki.png"
    //     },
    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/bala.png"
    //     },
    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/rehan.png"
    //     },
    //     {
    //         src: "https://ik.imagekit.io/mhcockpit1/placed-students/Sana.png"
    //     },

    // ]

    const VelsLandingGallery = Array.from({ length: 62 }, (_, i) => ({
        src: `https://ik.imagekit.io/mhcockpit1/vels%20gallery/${i + 1}.webp`,
    }));

    const Placed1 = Array.from({ length: 36 }, (_, i) => {
        const url = `https://ik.imagekit.io/mhcockpit1/Students/Placed%20Students/${i + 1}.png`;
        // Check if the URL should be excluded
        if (url === "https://ik.imagekit.io/mhcockpit1/Students/Placed%20Students/3.png" || url === "https://ik.imagekit.io/mhcockpit1/Students/Placed%20Students/7.png") {
            return null;  // Return null for the URLs to be excluded
        }
        return { src: url };
    }).filter(item => item !== null);

    const Placed2 = Array.from({ length: 36 }, (_, i) => ({
        src: `https://ik.imagekit.io/mhcockpit1/Students/Placed%20Students/${i + 36}.png`,
    }));

    return (
        <div>
            <div>
                <img src='https://ik.imagekit.io/mhcockpit1/Vels%20Landing/2025/Vels%20Admissions%202k25-2k26.jpg' className='w-full' alt='Vels University' />
            </div>
            <div className='flex justify-center items-center py-10 max-sm:py-5'>
                <FadeInSectionDownToUp>
                    <img className='w-96 max-sm:w-56' src='https://ik.imagekit.io/mhcockpit1/Vels%20Landing/2025/Vels%20New%20Logo.png' alt='Vels University' />
                </FadeInSectionDownToUp>
            </div>
            <div className='flex justify-center items-center py-10 max-sm:py-5'>
                <FadeInSectionDownToUp>
                    <img className='max-sm:w-56' src='https://ik.imagekit.io/mhcockpit1/Vels%20Landing/2025/12b%20png.png' alt='Vels University' />
                </FadeInSectionDownToUp>
            </div>
            <div className='relative'>
                <div className='flex justify-center items-center'>
                    <FadeInSectionDownToUp>
                        <img src='https://ik.imagekit.io/mhcockpit1/Vels%20Landing/VELS%20BUILDING.png' alt='Vels University' />
                    </FadeInSectionDownToUp>
                </div>
                <div className='flex justify-center items-center absolute bottom-0 left-1/3 right-1/3'>
                    <FadeInSectionDownToUp>
                        <img className='w-96' src='https://ik.imagekit.io/mhcockpit1/Vels%20Landing/Benefits%20of%20%20Doing%20Aviation%20at%20%20VELS%20University..png' alt='Vels University' />
                    </FadeInSectionDownToUp>
                </div>
            </div>
            <div className='flex justify-center items-center py-10 max-sm:py-5'>
                <FadeInSectionDownToUp>
                    <img className='w-96 max-sm:w-56' src='https://ik.imagekit.io/mhcockpit1/Vels%20Landing/32%20LPA.png' alt='Vels University' />
                </FadeInSectionDownToUp>
            </div>
            <div>
                <Marquee speed={100} loop={1000} pauseOnHover>
                    {Placed1.map((placed, index) => (
                        <div key={index}>
                            <img src={placed.src} alt='Placed' className='w-36 max-sm:w-20 m-2' />
                        </div>
                    ))}
                </Marquee>
                <Marquee speed={100} direction='right' loop={1000} pauseOnHover>
                    {Placed2.map((placed, index) => (
                        <div key={index}>
                            <img src={placed.src} alt='Placed' className='w-36 max-sm:w-20 m-2' />
                        </div>
                    ))}
                </Marquee>
            </div>
            {/* <div className='flex justify-center items-center'>
                <div className='grid grid-cols-6 gap-20 xl:gap-20 sm:gap-10 max-sm:gap-5 px-36 max-sm:px-10 py-5'>
                    {VelsLandingPlacedRow1.map((image, index) => (
                        <div key={index}>
                            <FadeInSectionDownToUp>
                                <img src={image.src} alt='Vels University' className='w-36' />
                            </FadeInSectionDownToUp>
                        </div>
                    ))}
                </div>
            </div>
            <div className='flex justify-center items-center'>
                <div className='grid grid-cols-7 gap-20 xl:gap-20 sm:gap-10 max-sm:gap-5 px-20 max-sm:px-5 py-5'>
                    {VelsLandingPlacedRow2.map((image, index) => (
                        <div key={index}>
                            <FadeInSectionDownToUp>
                                <img src={image.src} alt='Vels University' className='w-36' />
                            </FadeInSectionDownToUp>
                        </div>
                    ))}
                </div>
            </div>
            <div className='flex justify-center items-center'>
                <div className='grid grid-cols-7 gap-20 xl:gap-20 sm:gap-10 max-sm:gap-5 px-20 max-sm:px-5 py-5'>
                    {VelsLandingPlacedRow3.map((image, index) => (
                        <div key={index}>
                            <FadeInSectionDownToUp>
                                <img src={image.src} alt='Vels University' className='w-36' />
                            </FadeInSectionDownToUp>
                        </div>
                    ))}
                </div>
            </div>
            <div className='flex justify-center items-center'>
                <div className='grid grid-cols-6 gap-20 xl:gap-20 sm:gap-10 max-sm:gap-5 px-36 max-sm:px-10 py-5'>
                    {VelsLandingPlacedRow4.map((image, index) => (
                        <div key={index}>
                            <FadeInSectionDownToUp>
                                <img src={image.src} alt='Vels University' className='w-36' />
                            </FadeInSectionDownToUp>
                        </div>
                    ))}
                </div>
            </div> */}
            <div className='flex justify-center items-center px-32 xl:px-32 sm:px-10 max-sm:px-5 py-20'>
                <div className='grid grid-cols-3 xl:grid-cols-3 sm:grid-cols-2 max-sm:grid-cols-1 gap-10 w-full'>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='text-xl font-semibold py-2'>Diverse Community</p>
                        </FadeInSectionDownToUp>
                        <div className='p-5 bg-[#06103d] text-white rounded-xl w-full h-full'>
                            <div className="text-lg text-justify">
                                <ul className='space-y-3'>
                                    <FadeInSectionDownToUp>
                                        <div className='flex'>
                                            <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                                VELS Aviation warmly invites students from all over India and around the world.
                                            </li>
                                        </div>
                                    </FadeInSectionDownToUp>
                                    <FadeInSectionDownToUp>
                                        <div className='flex'>
                                            <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                                Our student body includes individuals from 15 different states across India, as well as Non-Resident Indian (NRI) and International students from 6 Countries.
                                            </li>
                                        </div>
                                    </FadeInSectionDownToUp>
                                    <FadeInSectionDownToUp>
                                        <div className='flex'>
                                            <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                                Our community is enriched by various cultures and experiences, creating a vibrant academic environment.
                                            </li>
                                        </div>
                                    </FadeInSectionDownToUp>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='text-xl font-semibold py-2'>Experienced Faculty</p>
                        </FadeInSectionDownToUp>
                        <div className='p-5 bg-[#06103d] text-white rounded-xl w-full h-full'>
                            <div className="text-lg text-justify">
                                <ul className='space-y-3'>
                                    <FadeInSectionDownToUp>
                                        <div className='flex'>
                                            <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                                Learn from faculty members with over 10 years of experience in the international airline industry.
                                            </li>
                                        </div>
                                    </FadeInSectionDownToUp>
                                    <FadeInSectionDownToUp>
                                        <div className='flex'>
                                            <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                                Gain valuable insights from professionals who have firsthand experience in aviation.
                                            </li>
                                        </div>
                                    </FadeInSectionDownToUp>
                                    <FadeInSectionDownToUp>
                                        <div className='flex'>
                                            <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                                Our faculty are dedicated to guiding students towards success in their aviation careers.
                                            </li>
                                        </div>
                                    </FadeInSectionDownToUp>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='text-xl font-semibold py-2'>Internships</p>
                        </FadeInSectionDownToUp>
                        <div className='p-5 bg-[#06103d] text-white rounded-xl w-full h-full'>
                            <div className="text-lg text-justify">
                                <ul className='space-y-3'>
                                    <FadeInSectionDownToUp>
                                        <div className='flex'>
                                            <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                                Students will commence their internships in their second year of study.
                                            </li>
                                        </div>
                                    </FadeInSectionDownToUp>
                                    <FadeInSectionDownToUp>
                                        <div className='flex'>
                                            <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                                We have established a Memorandum of Understanding (MoU) with the Airports Authority of India (AAI) to facilitate internship opportunities.
                                            </li>
                                        </div>
                                    </FadeInSectionDownToUp>
                                    <FadeInSectionDownToUp>
                                        <div className='flex'>
                                            <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                                Internships will be available at international airports, airlines, and aircraft maintenance organizations.
                                            </li>
                                        </div>
                                    </FadeInSectionDownToUp>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='text-xl font-semibold py-2'>Cutting- Edge Curriculum</p>
                        </FadeInSectionDownToUp>
                        <div className='p-5 bg-[#06103d] text-white rounded-xl w-full h-full'>
                            <div className="text-lg text-justify">
                                <ul className='space-y-3'>
                                    <FadeInSectionDownToUp>
                                        <div className='flex'>
                                            <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                                Practical skills are developed using the DA 40 simulator in our lab.
                                            </li>
                                        </div>
                                    </FadeInSectionDownToUp>
                                    <FadeInSectionDownToUp>
                                        <div className='flex'>
                                            <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                                The program features immersive VR training for simulator control.
                                            </li>
                                        </div>
                                    </FadeInSectionDownToUp>
                                    <FadeInSectionDownToUp>
                                        <div className='flex'>
                                            <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                                Training meets Directorate General of Civil Aviation (DGCA) standards for CPL.
                                            </li>
                                        </div>
                                    </FadeInSectionDownToUp>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='text-xl font-semibold py-2'>Industrial Visits</p>
                        </FadeInSectionDownToUp>
                        <div className='p-5 bg-[#06103d] text-white rounded-xl w-full h-full'>
                            <div className="text-lg text-justify">
                                <ul className='space-y-3'>
                                    <FadeInSectionDownToUp>
                                        <div className='flex'>
                                            <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                                We help students understand the industry.
                                            </li>
                                        </div>
                                    </FadeInSectionDownToUp>
                                    <FadeInSectionDownToUp>
                                        <div className='flex'>
                                            <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                                We arrange visits to airports, airlines, maintenance organizations, and aerospace companies.
                                            </li>
                                        </div>
                                    </FadeInSectionDownToUp>
                                    <FadeInSectionDownToUp>
                                        <div className='flex'>
                                            <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                                Students see real-world operations and gain valuable knowledge.
                                            </li>
                                        </div>
                                    </FadeInSectionDownToUp>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='text-xl font-semibold py-2'>Higher Placement</p>
                        </FadeInSectionDownToUp>
                        <div className='p-5 bg-[#06103d] text-white rounded-xl w-full h-full'>
                            <div className="text-lg text-justify">
                                <ul className='space-y-3'>
                                    <FadeInSectionDownToUp>
                                        <div className='flex'>
                                            <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                                VELS Aviation ensures graduates secure excellent aviation career opportunities.
                                            </li>
                                        </div>
                                    </FadeInSectionDownToUp>
                                    <FadeInSectionDownToUp>
                                        <div className='flex'>
                                            <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                                Success Rate: The institution has achieved a 100% success rate in placing students in the aviation industry.
                                            </li>
                                        </div>
                                    </FadeInSectionDownToUp>
                                    <FadeInSectionDownToUp>
                                        <div className='flex'>
                                            <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                                Highest Salary Package: Students have access to competitive salary packages, with the highest reaching ₹33 LPA.
                                            </li>
                                        </div>
                                    </FadeInSectionDownToUp>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex justify-center items-center px-32 xl:px-32 sm:px-10 max-sm:px-5 py-10'>
                <div className='grid grid-cols-2 xl:grid-cols-2 sm:grid-cols-2 max-sm:grid-cols-1 gap-10'>
                    <div>
                        <div className='p-5 bg-[#1e90ff] text-white rounded-xl w-full h-full'>
                            <FadeInSectionDownToUp>
                                <p className='text-xl font-semibold py-2'>Courses Offered.</p>
                            </FadeInSectionDownToUp>
                            <div className="text-lg text-justify">
                                <ul className='space-y-3'>
                                    <FadeInSectionDownToUp>
                                        <div className='flex'>
                                            <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                                B.Sc. Aviation.
                                            </li>
                                        </div>
                                    </FadeInSectionDownToUp>
                                    <FadeInSectionDownToUp>
                                        <div className='flex'>
                                            <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                                B.Sc. Aeronautical Science.
                                            </li>
                                        </div>
                                    </FadeInSectionDownToUp>
                                    <FadeInSectionDownToUp>
                                        <div className='flex'>
                                            <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                                BBA in Aviation Management.
                                            </li>
                                        </div>
                                    </FadeInSectionDownToUp>
                                    <FadeInSectionDownToUp>
                                        <div className='flex'>
                                            <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                                MBA in Aviation and Airport Management.
                                            </li>
                                        </div>
                                    </FadeInSectionDownToUp>
                                    <FadeInSectionDownToUp>
                                        <div className='flex'>
                                            <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                                Diploma in Cabin Crew.
                                            </li>
                                        </div>
                                    </FadeInSectionDownToUp>
                                    <FadeInSectionDownToUp>
                                        <div className='flex'>
                                            <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                                Diploma in Airport Management.
                                            </li>
                                        </div>
                                    </FadeInSectionDownToUp>
                                    <FadeInSectionDownToUp>
                                        <div className='flex'>
                                            <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                                PG Diploma in Aircraft Maintenance.
                                            </li>
                                        </div>
                                    </FadeInSectionDownToUp>
                                    {/* <FadeInSectionDownToUp>
                                        <div className='flex'>
                                            <span className='text-xl font-bold mr-2'>&#8226;</span><li className=''>
                                                B.Tech Aerospace Engineering.
                                            </li>
                                        </div>
                                    </FadeInSectionDownToUp> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='w-full h-full'>
                        <div className='p-5 bg-[#1e90ff] text-white rounded-xl w-full h-full'>
                            <FadeInSectionDownToUp>
                                <p className='text-xl font-semibold py-2'>Eligibility.</p>
                            </FadeInSectionDownToUp>
                            <div className="text-lg text-justify">
                                <FadeInSectionDownToUp>
                                    <p>
                                        10th & 12th with Maths & Physics or its equivalent <br className='max-sm:hidden xl:block sm:hidden' />
                                        such as (3 years Engineering Diploma) from a <br className='max-sm:hidden xl:block sm:hidden' />
                                        Recognised Board.
                                    </p>
                                </FadeInSectionDownToUp>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
<div className='flex justify-center items-center gap-20'>
<div className='flex justify-center animate-bounce'>
                <FadeInSectionDownToUp>
                    <a
                        className='bg-gradient-to-b hover:bg-gradient-to-t from-[#fc8d06] to-[#ff4d00] text-white text-xl font-semibold p-3 rounded-lg'
                        href='https://erp.vistas.ac.in/velsonline/applicationist/loginManager/applicantRegistration.jsp'
                        target='blank'
                    >
                        Apply Now
                    </a>
                </FadeInSectionDownToUp>
            </div>
            <div className='flex justify-center animate-bounce'>
                <FadeInSectionDownToUp>
                    <a
                        className='bg-gradient-to-b hover:bg-gradient-to-t from-[#fc8d06] to-[#ff4d00] text-white text-xl font-semibold p-3 rounded-lg'
                        href='https://drive.google.com/file/d/1Q-48zmxwtnaHlHjzyoJDJClK_yHGgbQY/view?usp=sharing'
                        target='blank'
                    >
                        Download Broucher
                    </a>
                </FadeInSectionDownToUp>
            </div>
</div>
            <div>
                <FadeInSectionDownToUp>
                    <p className='text-center text-3xl font-semibold py-5'>
                        Top Recruiters
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div>
                <Marquee speed={100} loop={1000}>
                    <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Air%20Asia%20logo.png' alt='Airline LOGO' />
                    <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Air_Canada_Logo.png' alt='Airline LOGO' />
                    <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Air_France-Logo.png' alt='Airline LOGO' />
                    <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Air_India_Express_Logo.png' alt='Airline LOGO' />
                    <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Air_New_Zealand_logo.png' alt='Airline LOGO' />
                    <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Air-Arabia-Logo.png' alt='Airline LOGO' />
                    <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/air-india-logo.png' alt='Airline LOGO' />
                    <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Air-Transat-Logo.png' alt='Airline LOGO' />
                    <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Akasa_Air_logo.svg.png' alt='Airline LOGO' />
                    <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Barrier%20Air.png' alt='Airline LOGO' />
                    <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/emirates-airlines.png' alt='Airline LOGO' />
                    <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Etihad-Airways-Logo.png' alt='Airline LOGO' />
                </Marquee>
                <Marquee speed={100} direction='right' loop={1000}>
                    <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/FlyDubai-Logo.png' alt='Airline LOGO' />
                    <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/indigo-vector-logo.png' alt='Airline LOGO' />
                    <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Japan%20Airlines.png' alt='Airline LOGO' />
                    <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Korean-Air-Logo.png' alt='Airline LOGO' />
                    <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Qantas-Logo.png' alt='Airline LOGO' />
                    <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Singapore%20Airlines.png' alt='Airline LOGO' />
                    <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/SpiceJet-Logo.png' alt='Airline LOGO' />
                    <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Swiss-International-Air-Lines-logo.png' alt='Airline LOGO' />
                    <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Turkish_Airlines_logo.png' alt='Airline LOGO' />
                    <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Vistara%20logo.png' alt='Airline LOGO' />
                    <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/WestJet-Logo.wine.png' alt='Airline LOGO' />
                    <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Wizz_Air_Logo.png' alt='Airline LOGO' />
                </Marquee>
            </div>
            <div>
                <FadeInSectionDownToUp>
                    <p className='text-center text-5xl text-[#1e90ff] font-semibold py-5'>
                        Gallery.
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='py-5'>
                <AliceCarousel
                    autoPlay
                    disableButtonsControls
                    disableDotsControls
                    autoPlayInterval={2000}
                    buttonsDisabled={false}
                    dotsDisabled={true}
                    infinite={true}
                    swipeable={true}
                    draggable={true}
                    responsive={{
                        0: { items: 1 },
                        568: { items: 1 },
                        1024: { items: 1 },
                    }}
                >
                    {VelsLandingGallery.map((image, index) => (
                        <div key={index}>
                            <img src={image.src} alt='Vels University' />
                        </div>
                    ))}
                </AliceCarousel>
            </div>
            <div className='flex justify-center items-center w-full px-20 max-sm:px-5 py-10'>
                <div className='grid grid-cols-2 max-sm:grid-cols-1 gap-10 w-full'>
                    <div>
                        <iframe className='w-full h-[300px] xl:h-[300px] sm:h-[200px] max-sm:h-[180px]' src="https://www.youtube.com/embed/p8C9b0f2OQY?si=eKPoQ5ikBgkgWccy" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                    <div>
                        <iframe className='w-full h-[300px] xl:h-[300px] sm:h-[200px] max-sm:h-[180px]' src="https://www.youtube.com/embed/lYJMSOEbVEI?si=5QLwntmgFGrzY1rw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                    <div>
                        <iframe className='w-full h-[300px] xl:h-[300px] sm:h-[200px] max-sm:h-[180px]' src="https://www.youtube.com/embed/y1UL7qBPD90?si=bHWXSTD3vYsSh6JD" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                    <div>
                        <iframe className='w-full h-[300px] xl:h-[300px] sm:h-[200px] max-sm:h-[180px]' src="https://www.youtube.com/embed/CZYKyFdv5iw?si=E0we5_phAbnVj40M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </div>
    )
}
