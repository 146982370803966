import React from 'react';
import FadeInSectionDownToUp from '../../Components/FadeAnimations/FadeInSectionDownToUp';

function BDM() {

    return (
        <div>
            <div className="relative text-justify">
                <div className="inset-0 overflow-hidden">
                    <img
                        className='w-full h-full object-cover animate-zoomOut'
                        src="https://ik.imagekit.io/mhcockpit1/Marketing%20Manager.webp"
                        alt="DGCA - License conversion"
                    />
                </div>
                <div className="relative py-10">
                    <div className='pl-40 xl:pl-40 sm:pl-20 max-sm:pl-10 text-4xl max-sm:text-2xl font-semibold tracking-tighter py-10'>
                        <FadeInSectionDownToUp>
                            <h1>Business Development Manager.</h1>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-40 xl:px-40 sm:px-20 max-sm:px-10 py-5 text-xl'>
                        <FadeInSectionDownToUp>
                            <p>
                                As a businees development manager you need to Develop, execute, and oversee a business strategy that prioritizes growth and positive customer ratings and Maintain positive professional relationships with clients &Use financial techniques to improve sales revenue,Monitor sales progress to ensure that corporate goals are being met ,Track all business-related invoices, Train sales professionals and help improve their skills.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-40 xl:px-40 sm:px-20 max-sm:px-10 py-5 text-3xl max-sm:text-xl font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                Responsibilities.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg px-40 xl:px-40 sm:px-20 max-sm:px-10 mb-5 text-justify">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Conduct high-level industry research to develop effective sales solutions.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Promote the company’s products or services to prospective clients.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Participate in collaborative business meetings to update key stakeholders.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Provide continuous, constructive feedback to salespeople.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Review sales contracts to ensure they meet legal and corporate guidelines.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Interact with clients and respond to important inquiries about the company’s products or services.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                    <div className='px-40 xl:px-40 sm:px-20 max-sm:px-10 py-5 text-3xl max-sm:text-xl font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                Skills and qualifications.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg px-40 xl:px-40 sm:px-20 max-sm:px-10 mb-5 text-justify">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Proven record of sales growth.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Experience in customer service, marketing, or a sales-related field.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Strong knowledge of business and sales growth techniques.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Exceptional project management skills.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Clear verbal and written communication skills.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Enthusiasm for the company and its growth potential.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                    <div className='px-40 xl:px-40 sm:px-20 max-sm:px-10 py-5 text-3xl max-sm:text-xl font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                Preferred qualifications.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg px-40 xl:px-40 sm:px-20 max-sm:px-10 mb-5 text-justify">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Experience in managing sales or marketing teams.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Sharp negotiation and networking skills.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Organizational skills.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Problem-solving skills.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Educational background in business, marketing, or finance.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                    <div className='flex justify-center items-center'>
                        <FadeInSectionDownToUp>
                            <button
                                className='px-5 py-1 bg-slate-600 text-white font-semibold rounded-lg animate-pulse hover:scale-105 transition-transform duration-700 ease-in-out'
                            >
                                <a href='https://forms.gle/BQKYYuKCrrqaae2KA' target='blank'>
                                    Apply Now
                                </a>
                            </button>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='flex justify-center items-center pt-2'>
                        <FadeInSectionDownToUp>
                            <a href='mailto:careers@mhcockpit.com' className='font-semibold text-xl'>careers@mhcockpit.com</a>
                        </FadeInSectionDownToUp>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BDM;
