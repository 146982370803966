// import React, { useRef } from 'react';
// import AliceCarousel from 'react-alice-carousel';
// import 'react-alice-carousel/lib/alice-carousel.css';
// import { GrNext, GrPrevious } from 'react-icons/gr';
// import { CollegesData } from '../../Components/Colleges/CollegesData';
// import FadeInSectionDownToUp from '../../Components/FadeAnimations/FadeInSectionDownToUp';

// // Define the button components
// const Button = ({ onClick, position, children }) => (
//     <button
//         className={`absolute top-1/2 transform -translate-y-1/2 bg-gray-700 bg-opacity-85 text-white p-2 rounded-full z-10 ${position === 'next' ? 'right-4' : 'left-4'}`}
//         onClick={onClick}
//     >
//         {children}
//     </button>
// );

// const CarouselSection = ({ items, title }) => {
//     const carouselRef = useRef(null);

//     const goToNext = () => {
//         if (carouselRef.current) {
//             carouselRef.current.slideNext();
//         }
//     };

//     const goToPrev = () => {
//         if (carouselRef.current) {
//             carouselRef.current.slidePrev();
//         }
//     };

//     return (
//         <div className="relative">
//             <FadeInSectionDownToUp>
//                 <p className="font-semibold text-3xl md:text-4xl lg:text-5xl tracking-tighter pl-4 py-8 md:pl-8 lg:pl-12 lg:py-10 text-gray-400">{title}</p>
//             </FadeInSectionDownToUp>
//             <AliceCarousel
//                 ref={carouselRef}
//                 autoPlay
//                 disableButtonsControls
//                 disableDotsControls
//                 autoPlayInterval={2000}
//                 buttonsDisabled={true}
//                 dotsDisabled={true}
//                 infinite={true}
//                 swipeable={true}
//                 draggable={true}
//                 responsive={{
//                     0: { items: 1 },
//                     568: { items: 2 },
//                     768: { items: 2 },
//                     1024: { items: 3 },
//                     1280: { items: 3 },
//                 }}
//             >
//                 {items.map((item, index) => (
//                     <div
//                         key={index}
//                         className='relative flex flex-col bg-opacity-25 shadow-lg shadow-gray-300 rounded-2xl overflow-hidden h-[650px] max-sm:h-[600px] mx-5 my-10 hover:scale-105 transition-transform duration-700 ease-in-out'
//                     >
//                         <a href={item.link} className='flex flex-col h-full' rel="noopener noreferrer">
//                             <img src={item.src} alt={item.alt} className='w-full h-1/2' />
//                             <div className='flex flex-col flex-grow p-4'>
//                                 <p className='font-semibold text-lg md:text-xl'>{item.title}</p>
//                                 <div className='flex flex-col md:flex-row justify-start gap-4 mt-2'>
//                                     <p>{item.location}📍</p>
//                                     <p className='text-gray-400'>({item.ugc})</p>
//                                 </div>
//                                 <p className='mt-2 text-center'>{item.courses}</p>
//                             </div>
//                             <div className='p-4 flex justify-center'>
//                                 <a href={item.link}>
//                                     <button className='bg-gray-300 px-4 py-2 rounded-lg text-center'>{item.button}</button>
//                                 </a>
//                             </div>
//                         </a>
//                     </div>
//                 ))}
//             </AliceCarousel>
//             <Button onClick={goToNext} position="next"><GrNext /></Button>
//             <Button onClick={goToPrev} position="prev"><GrPrevious /></Button>
//         </div>
//     );
// };

// export default function Colleges() {
//     return (
//         <div>
//             <CarouselSection items={CollegesData} title="Colleges." />
//         </div>
//     );
// }


import React, { useState, useEffect } from 'react';
import { colleges, courses } from '../../Components/Colleges/CollegesData';

const Colleges = () => {
    // Step 1: Manage selected degree, course, and filtered colleges in state
    const [selectedDegree, setSelectedDegree] = useState('BSc');
    const [selectedCourse, setSelectedCourse] = useState('Aviation');
    const [filteredColleges, setFilteredColleges] = useState([]);
    
    // Combine all colleges into a single array for initial view (without duplicates)
    const allColleges = Object.values(colleges).flat();

    // Step 2: Filter out duplicate colleges based on their name
    const uniqueColleges = Array.from(new Set(allColleges.map(a => a.name)))
        .map(name => allColleges.find(a => a.name === name));

    // Step 3: Set all colleges without any filter on page load
    useEffect(() => {
        setFilteredColleges(uniqueColleges); // Show unique colleges initially
    }, []);

    // Step 4: Handle degree change and filter colleges accordingly
    const handleDegreeChange = (e) => {
        const newDegree = e.target.value;
        setSelectedDegree(newDegree);
        const defaultCourse = courses[newDegree][0]; // Default to the first course in the new degree
        setSelectedCourse(defaultCourse);

        // Filter colleges based on the selected degree and course
        const filtered = colleges[`${newDegree}-${defaultCourse}`] || [];
        setFilteredColleges(filtered);
    };

    const handleCourseChange = (e) => {
        const newCourse = e.target.value;
        setSelectedCourse(newCourse);

        // Filter colleges based on the selected degree and course
        const filtered = colleges[`${selectedDegree}-${newCourse}`] || [];
        setFilteredColleges(filtered);
    };

    return (
        <div>
            <h1 className="font-semibold text-3xl md:text-4xl lg:text-5xl tracking-tighter pl-4 py-8 md:pl-8 lg:pl-12 lg:py-10 text-gray-400">Colleges.</h1>

            <div className='flex justify-start items-center pl-4 md:pl-8 lg:pl-12 w-1/4 max-sm:w-full'>
                <div className='grid grid-cols-2 gap-5'>
                    {/* Degree Dropdown */}
                    <div className="mb-4">
                        <select
                            id="degree"
                            value={selectedDegree}
                            onChange={handleDegreeChange}
                            className="w-full p-3 border border-gray-300 rounded-md"
                        >
                            {Object.keys(courses).map((degree) => (
                                <option key={degree} value={degree}>
                                    {degree}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* Course Dropdown */}
                    <div className="mb-4">
                        <select
                            id="course"
                            value={selectedCourse}
                            onChange={handleCourseChange}
                            className="w-full p-3 border border-gray-300 rounded-md"
                        >
                            {courses[selectedDegree].map((course) => (
                                <option key={course} value={course}>
                                    {course}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>

            {/* Colleges Grid */}
            <div className="mt-6 px-4 md:px-8 lg:px-12">
                {/* <h2 className="text-xl font-semibold mb-4">Colleges Offering {selectedDegree} - {selectedCourse}</h2> */}
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-6 pb-10">
                    {filteredColleges.length > 0 ? (
                        filteredColleges.map((college, index) => (
                            <div key={index} className="bg-white pb-14 rounded-lg shadow-lg relative hover:scale-105 transition-all ease-in-out duration-500">
                                <a href={college.applyNow}>
                                    {/* College Image */}
                                    <img
                                        src={college.image}
                                        alt={college.name}
                                        className="w-full rounded-lg mb-4"
                                    />
                                    {/* College Info */}
                                    <div className="text-center">
                                        <h3 className="text-lg font-semibold text-gray-700 text-start pl-5">{college.name}</h3>
                                        <div className='flex justify-start items-center pl-5'>
                                            <p className="text-sm">{college.location}📍</p>
                                            <p className="text-sm text-gray-400">{college.accreditation}</p>
                                        </div>
                                        <p className="text-sm text-gray-600 mt-2 px-5">{college.courses}</p>
                                        <div className="mt-4 bottom-2 left-1/3 right-1/3 absolute">
                                            <a
                                                href={college.applyNow}
                                                // target="_blank"
                                                rel="noopener noreferrer"
                                                className="inline-block bg-gray-300 px-4 py-2 rounded-lg text-center"
                                            >
                                                Apply Now
                                            </a>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        ))
                    ) : (
                        <p>No colleges available for this selection.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Colleges;


