import React from 'react';
import FadeInSectionDownToUp from '../../Components/FadeAnimations/FadeInSectionDownToUp';

function CabinCrewInstructor() {

    return (
        <div>
            <div className="relative text-justify">
                <div className="inset-0 overflow-hidden">
                    <img
                        className='w-full h-full object-cover animate-zoomOut'
                        src="https://ik.imagekit.io/mhcockpit1/Cabin%20crew.jpg"
                        alt="DGCA - License conversion"
                    />
                </div>
                <div className="relative py-10">
                    <div className='pl-40 xl:pl-40 sm:pl-20 max-sm:pl-10 text-4xl max-sm:text-2xl font-semibold tracking-tighter py-10'>
                        <FadeInSectionDownToUp>
                            <h1>Cabin Crew Instructor.</h1>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-40 xl:px-40 sm:px-20 max-sm:px-10 py-5 text-xl'>
                        <FadeInSectionDownToUp>
                            <p>
                                A Cabin Crew Instructor is responsible for providing training to individuals aspiring to become cabin crew members or flight attendants. Their role involves teaching and preparing students for the various responsibilities and challenges associated with working in the aviation industry.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='px-40 xl:px-40 sm:px-20 max-sm:px-10 py-5 text-3xl max-sm:text-xl font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                Curriculum Development.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg px-40 xl:px-40 sm:px-20 max-sm:px-10 mb-5 text-justify">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Develop and update training programs and curriculum to ensure that they align with industry regulations, company policies, and safety standards.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Create instructional materials, presentations, and training aids that effectively convey information to students.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                    <div className='px-40 xl:px-40 sm:px-20 max-sm:px-10 py-5 text-3xl max-sm:text-xl font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                Classroom Instruction.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg px-40 xl:px-40 sm:px-20 max-sm:px-10 mb-5 text-justify">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Conduct classroom training sessions to educate students on topics such as safety procedures, emergency protocols, customer service, and hospitality.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Instruct on the proper use of cabin equipment, emergency exits, and safety features of the aircraft.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                    <div className='px-40 xl:px-40 sm:px-20 max-sm:px-10 py-5 text-3xl max-sm:text-xl font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                Practical Training.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg px-40 xl:px-40 sm:px-20 max-sm:px-10 mb-5 text-justify">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Organize and conduct practical training sessions in mock-up cabins or simulation environments to simulate real-world scenarios.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Provide hands-on training in areas like first aid, firefighting, and the use of safety equipment.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                    <div className='px-40 xl:px-40 sm:px-20 max-sm:px-10 py-5 text-3xl max-sm:text-xl font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                Regulatory Compliance.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg px-40 xl:px-40 sm:px-20 max-sm:px-10 mb-5 text-justify">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Stay updated on aviation regulations, guidelines, and industry best practices to ensure that training programs comply with all relevant standards.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Prepare students for examinations and assessments required for certification by aviation authorities.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                    <div className='px-40 xl:px-40 sm:px-20 max-sm:px-10 py-5 text-3xl max-sm:text-xl font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                Communication Skills.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg px-40 xl:px-40 sm:px-20 max-sm:px-10 mb-5 text-justify">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Develop students' communication skills, including how to interact with passengers, handle challenging situations, and provide exceptional customer service.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Emphasize the importance of effective teamwork and communication among cabin crew members.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                    <div className='px-40 xl:px-40 sm:px-20 max-sm:px-10 py-5 text-3xl max-sm:text-xl font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                Emergency Procedures.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg px-40 xl:px-40 sm:px-20 max-sm:px-10 mb-5 text-justify">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Instruct on emergency procedures, including evacuations, handling medical situations, and responding to security threats.Conduct drills and simulations to ensure that cabin crew members can respond confidently and efficiently in emergency situations.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Conduct drills and simulations to ensure that cabin crew members can respond confidently and efficiently in emergency situations.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                    <div className='px-40 xl:px-40 sm:px-20 max-sm:px-10 py-5 text-3xl max-sm:text-xl font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                Customer Service Training.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg px-40 xl:px-40 sm:px-20 max-sm:px-10 mb-5 text-justify">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Train cabin crew members on providing excellent customer service, dealing with passenger inquiries and requests, and maintaining a positive and professional demeanor.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                    <div className='px-40 xl:px-40 sm:px-20 max-sm:px-10 py-5 text-3xl max-sm:text-xl font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                Assessment and Feedback.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg px-40 xl:px-40 sm:px-20 max-sm:px-10 mb-5 text-justify">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Assess students' performance through examinations, practical assessments, and simulations.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Provide constructive feedback and guidance to help students improve their skills and meet industry standards.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                    <div className='px-40 xl:px-40 sm:px-20 max-sm:px-10 py-5 text-3xl max-sm:text-xl font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                Professional Development.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg px-40 xl:px-40 sm:px-20 max-sm:px-10 mb-5 text-justify">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Stay updated on industry trends, technological advancements, and changes in regulations to continuously enhance personal and professional knowledge.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Participate in ongoing training to maintain and upgrade instructor qualifications.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                    <div className='px-40 xl:px-40 sm:px-20 max-sm:px-10 py-5 text-3xl max-sm:text-xl font-semibold'>
                        <FadeInSectionDownToUp>
                            <p>
                                Collaboration.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className="text-lg px-40 xl:px-40 sm:px-20 max-sm:px-10 mb-5 text-justify">
                        <ul className='space-y-3'>
                            <FadeInSectionDownToUp>
                                <div className='flex'>
                                    <span className='text-xl font-bold mr-2'>&#9992;</span><li className=''>
                                        Collaborate with other instructors, aviation professionals, and training departments to ensure a comprehensive and cohesive training program.
                                    </li>
                                </div>
                            </FadeInSectionDownToUp>
                        </ul>
                    </div>
                    <div className='px-40 xl:px-40 sm:px-20 max-sm:px-10 py-5 text-xl'>
                        <FadeInSectionDownToUp>
                            <p>
                                Cabin Crew Instructors play a crucial role in shaping the next generation of cabin crew members, ensuring they are well-prepared for the challenges of the aviation industry. Effective communication, strong instructional skills, and a thorough understanding of aviation regulations are key attributes for success in this role.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='flex justify-center items-center'>
                        <FadeInSectionDownToUp>
                            <button
                                className='px-5 py-1 bg-slate-600 text-white font-semibold rounded-lg animate-pulse hover:scale-105 transition-transform duration-700 ease-in-out'
                            >
                                <a href='https://forms.gle/BQKYYuKCrrqaae2KA' target='blank'>
                                    Apply Now
                                </a>
                            </button>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='flex justify-center items-center pt-2'>
                        <FadeInSectionDownToUp>
                            <a href='mailto:careers@mhcockpit.com' className='font-semibold text-xl'>careers@mhcockpit.com</a>
                        </FadeInSectionDownToUp>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CabinCrewInstructor;
