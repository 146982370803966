import React, { useEffect, useRef } from 'react'
import FadeInSectionDownToUp from '../../../Components/FadeAnimations/FadeInSectionDownToUp';

export default function OurValues() {

    const videoRef = useRef(null);

    useEffect(() => {
        const video = videoRef.current;

        if (video) {
            const playPromise = video.play();

            if (playPromise !== undefined) {
                playPromise
                    .then(_ => {
                        // Autoplay started
                        console.log('Autoplay started');
                    })
                    .catch(error => {
                        // Autoplay was prevented, unmute and play
                        video.muted = true; // Unmute the video
                        video.play().catch(error => {
                            console.error('Autoplay was blocked:', error);
                        });
                    });
            }
        }
    }, []);

    const OurValuesIcon = [

        {
            src: "https://ik.imagekit.io/mhcockpit1/Our%20Values/Icon_1.png",
            title: "Client-Centric Commitment"
        },
        {
            src: "https://ik.imagekit.io/mhcockpit1/Our%20Values/Icon_2.png",
            title: "Ethical Excellence"
        },
        {
            src: "https://ik.imagekit.io/mhcockpit1/Our%20Values/Icon_3.png",
            title: "Reliability & Safety"
        },
        {
            src: "https://ik.imagekit.io/mhcockpit1/Our%20Values/Icon_4.png",
            title: "Harmony in Colloboration"
        },

    ]

    return (
        <div>
            <div className=''>
                <video autoPlay loop playsInline>
                    <source src={"https://mh-cognition.blr1.cdn.digitaloceanspaces.com/About-Us/Our%20Values%20New1.mp4.mp4"} type='video/mp4' />
                </video>
            </div>
            <div className='py-10'>
                <FadeInSectionDownToUp>
                    <h1 className='text-3xl text-gray-500 text-center'>
                        Our <span className='font-semibold text-[#01DA58]'>Values.</span>
                    </h1>
                </FadeInSectionDownToUp>
            </div>
            <div className='py-5'>
                <FadeInSectionDownToUp>
                    <p className='text-gray-500 px-10 max-sm:px-7 text-xl text-justify'>
                        <b>MH Cockpit</b>, as an Indian Aviation Training, Technology, and Research Organization, is guided by a set of core values that define the behaviours
                        of its employees and shape the DNA of its business. These values have been carefully chosen by the individuals who embody them, reflecting
                        the unique blend of qualities found in every member of the <b>MH Cockpit</b> family.
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='grid grid-cols-4 xl:grid-cols-4 sm:grid-cols-2 max-sm:grid-cols-2 py-5'>
                {OurValuesIcon.map((icon, index) => (
                    <div key={index}>
                        <center>
                            <FadeInSectionDownToUp>
                                <img src={icon.src} alt='Our Values' />
                                <p className='font-semibold text-[#01DA58] text-xl max-sm:text-sm'>{icon.title}</p>
                            </FadeInSectionDownToUp>
                        </center>
                    </div>
                ))}
            </div>
        </div>
    )
}
