import React, { useEffect, useRef, useState } from 'react'
import "../../Components/Home/Home.css"
import { Link } from 'react-router-dom';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { FaUserGraduate } from "react-icons/fa";
import { MdFlight } from "react-icons/md";
import { FaBookOpenReader } from "react-icons/fa6";
import { HiMiniBuildingLibrary } from "react-icons/hi2";
import Marquee from 'react-fast-marquee';
import { LMS } from '../../Components/Home/LMS';
import Testimonials from '../../Components/Home/Testimonials';
import TestimonialsMobile from '../../Components/Home/TestimonialsMobile';
import FadeInSectionDownToUp from '../../Components/FadeAnimations/FadeInSectionDownToUp';

export default function Home() {

    // const [count, setCount] = useState(0);

    // useEffect(() => {
    //     let start = 0;
    //     const end = 2000;
    //     const duration = 5000;
    //     const incrementTime = (duration / end) * 10;

    //     const counter = setInterval(() => {
    //         start += 10;
    //         if (start >= end) {
    //             start = end;
    //             clearInterval(counter);
    //         }
    //         setCount(start);
    //     }, incrementTime);
    // }, []);

    const [count, setCount] = useState(0);
    const [isVisible, setIsVisible] = useState(false);
    const counterRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            if (counterRef.current) {
                const rect = counterRef.current.getBoundingClientRect();
                const isInView = rect.top >= 0 && rect.bottom <= window.innerHeight;
                setIsVisible(isInView);
            }
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll(); // Check on mount

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (!isVisible) return;

        let start = 0;
        const end = 2000;
        const duration = 5000;
        const incrementTime = (duration / end) * 10;

        const counter = setInterval(() => {
            start += 10;
            if (start >= end) {
                start = end;
                clearInterval(counter);
            }
            setCount(start);
        }, incrementTime);

        return () => clearInterval(counter);
    }, [isVisible]);

    return (
        <div>
            <div>
                <AliceCarousel
                    autoPlay
                    disableButtonsControls
                    disableDotsControls
                    autoPlayInterval={2000}
                    buttonsDisabled={false}
                    dotsDisabled={true}
                    infinite={true}
                    swipeable={true}
                    draggable={true}
                    responsive={{
                        0: { items: 1 },
                        568: { items: 1 },
                        1024: { items: 1 },
                    }}
                >
                    <div><img src="https://ik.imagekit.io/mhcockpit1/COVER-%20HOME%20PAGE.webp" alt="Comprehensive guide to top aviation colleges in Chennai for aspiring pilots and aviation students by MHCockpit Aviation." title='Aviation Colleges in Chennai | MHCockpit Aviation' preload="auto" /></div>
                    <div><img src="https://ik.imagekit.io/mhcockpit1/crescent%20cover.jpg" alt="Top aviation college information and pilot training guidance provided by MHCockpit Aviation for aspiring students." title='Top Aviation College | MHCockpit Aviation Guide' preload="auto" /></div>
                    <div><img src="https://ik.imagekit.io/mhcockpit1/VELS%20WEB%20COVER%20PIC%202.webp" alt="MH Cockpit" preload="auto" /></div>
                    <div><img src="https://ik.imagekit.io/mhcockpit1/Cap_Dinesh%20NEW.webp" alt="Top aviation college information and pilot training guidance provided by MHCockpit Aviation for aspiring students." title='Top Aviation College | MHCockpit Aviation Guide' preload="auto" /></div>
                    <div><Link to='https://www.mhintellect.com/' target='blank'><img src="https://ik.imagekit.io/mhcockpit1/home%20MH%20Intellect%20Cover.webp" alt="MH Cockpit" preload="auto" /></Link></div>
                    <div><img src="https://ik.imagekit.io/mhcockpit1/MHC%20WEB%20COVER%20(2).jpg" alt="MH Cockpit" preload="auto" /></div>
                    <div><img src="https://ik.imagekit.io/mhcockpit1/CPL.jpg" alt="DGCA CPL pilot exam preparation designed for aspiring pilots and students by MHCockpit Aviation." title='DGCA CPL Pilot Exam Guide | MHCockpit Aviation' preload="auto" /></div>
                    <div><img src="https://ik.imagekit.io/mhcockpit1/Cover%20AME.jpg" alt="Comprehensive guide for DGCA AME exam preparation designed for aspiring aircraft maintenance engineers by MHCockpit." title='DGCA AME Exam Guide | MHCockpit Aviation' preload="auto" /></div>
                </AliceCarousel>
            </div>
            <div className='pt-20'>
                <FadeInSectionDownToUp>
                    <p className='text-justify xl:px-52 md:px-28 max-md:px-20 max-sm:px-5'>
                        Welcome to MH Cockpit, a pioneering aviation company at the forefront of the industry. We specialize in diverse verticals, including Training, Technology, Manpower, Consulting, Charter, and R&D. Our expertise spans Fixed Wing, Rotary Wing, and Drones. At MH Cockpit, we seamlessly integrate Artificial Intelligence and Robotics into our training division, dedicated to imparting unparalleled knowledge to the next generation of aviators and enthusiasts in AI & Robotics. Explore the skies of innovation with MH Cockpit, where excellence meets the future of aviation.
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className="flex justify-center py-20 xl:px-52 md:px-28 max-md:px-20 max-sm:px-5">
                <div className="grid grid-cols-1 xl:grid-cols-5 md:grid-cols-2 max-sm:grid-cols-2 sm:grid-cols-2 gap-4">
                    <FadeInSectionDownToUp>
                        <div className="flex flex-col items-center">
                            <FaUserGraduate size={50} color='blue' />
                            <h1 ref={counterRef} className="text-3xl"><b>{count}+</b></h1>
                            <p className="text-center">Students from partnered Training Delivery Centres India & Overseas.</p>
                        </div>
                    </FadeInSectionDownToUp>
                    <FadeInSectionDownToUp>
                        <div className="flex flex-col items-center space-y-9">
                            <MdFlight size={50} color='blue' />
                            <p className="text-center">Exclusive Indian Partner for internationally recognized FTOs.</p>
                        </div>
                    </FadeInSectionDownToUp>
                    <FadeInSectionDownToUp>
                        <div className="flex flex-col items-center space-y-9">
                            <FaBookOpenReader size={50} color='blue' />
                            <p className="text-center">Industrial Experts as Instructors.</p>
                        </div>
                    </FadeInSectionDownToUp>
                    <FadeInSectionDownToUp>
                        <div className="flex flex-col items-center space-y-9">
                            <HiMiniBuildingLibrary size={50} color='blue' />
                            <p className="text-center">Preferred Academic Partner for Leading Universities / Institution in India.</p>
                        </div>
                    </FadeInSectionDownToUp>
                    <FadeInSectionDownToUp>
                        <div className="flex flex-col items-center space-y-9">
                            <h1 className="text-5xl text-blue-700"><b>10+</b></h1>
                            <p className="text-center">Partnered Training Delivery Centres India & Overseas.</p>
                        </div>
                    </FadeInSectionDownToUp>
                </div>
            </div>
            <div className='space-y-5'>
                <FadeInSectionDownToUp>
                    <p className='text-justify xl:px-52 md:px-28 max-md:px-20 max-sm:px-5'>
                        At MH Cockpit, we're not just another aviation education provider; we're the trailblazers, the pioneers, and the champions of innovation in India's skies. With a relentless passion for raising the bar in learning standards, we're the trusted allies of universities, creating tailor-made training hubs that transform their aviation programs into unparalleled educational experiences. Our secret sauce? It's in the art of crafting dynamic curricula that dance to the rhythm of industry demands, ensuring our students are always one step ahead in their aviation journey. From the drawing board to the runway, we cover every aspect of aviation education, seamlessly blending curriculum design, training delivery, student admissions, and career placement support into a symphony of success. With our wings spread wide, we've forged partnerships with eight prestigious universities across India, including household names like <a href='/vels-university' className='text-blue-600 underline'>Vels University Chennai</a>, <a href='/jamal-mohamed-college' className='text-blue-600 underline'>Jamal Mohamed College</a>, <a href='/crescent-landing' className='text-blue-600 underline'>Crescent University Chennai</a> and <a href='https://univirteducation.in/' target='blank' className='text-blue-600 underline'>Malla Reddy University</a>. And with nearly 2000 students soaring through our programs, our impact is sky-high and ever-expanding.
                    </p>
                </FadeInSectionDownToUp>
                <FadeInSectionDownToUp>
                    <p className='text-justify xl:px-52 md:px-28 max-md:px-20 max-sm:px-5'>
                        But wait, there's more! Enter our Commercial Pilot Licensing <a href='/commercial-pilot-license' className='text-blue-600 underline'>(CPL) program</a> – the crown jewel of our commitment to excellence. This isn't your average flight school; it's a transformative journey designed to equip our students with the skills, knowledge, and confidence to conquer the skies. With cutting-edge facilities and seasoned instructors guiding their flight path, our CPL students don't just learn; they thrive. And that's not all – we're breaking new ground with our drone programs, igniting the spark of curiosity and innovation in young minds from grades 5 to 12. From traditional planes to futuristic drones, we're shaping the next generation of aviation leaders, one flight at a time. So, whether you're dreaming of soaring through the clouds as a commercial pilot or exploring the endless possibilities of aerospace technology, MH Cockpit is your launchpad to success. Join us, and together, let's reach for the stars.
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='text-center py-24 text-4xl font-semibold'>
                <FadeInSectionDownToUp>
                    <p>Potential Recruiters</p>
                </FadeInSectionDownToUp>
            </div>
            <Marquee speed={100} loop={1000}>
                <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Air%20Asia%20logo.png' alt='Airline LOGO' />
                <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Air_Canada_Logo.png' alt='Airline LOGO' />
                <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Air_France-Logo.png' alt='Airline LOGO' />
                <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Air_India_Express_Logo.png' alt='Airline LOGO' />
                <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Air_New_Zealand_logo.png' alt='Airline LOGO' />
                <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Air-Arabia-Logo.png' alt='Airline LOGO' />
                <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/air-india-logo.png' alt='Airline LOGO' />
                <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Air-Transat-Logo.png' alt='Airline LOGO' />
                <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Akasa_Air_logo.svg.png' alt='Airline LOGO' />
                <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Barrier%20Air.png' alt='Airline LOGO' />
                <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/emirates-airlines.png' alt='Airline LOGO' />
                <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Etihad-Airways-Logo.png' alt='Airline LOGO' />
            </Marquee>
            <Marquee speed={100} direction='right' loop={1000}>
                <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/FlyDubai-Logo.png' alt='Airline LOGO' />
                <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/indigo-vector-logo.png' alt='Airline LOGO' />
                <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Japan%20Airlines.png' alt='Airline LOGO' />
                <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Korean-Air-Logo.png' alt='Airline LOGO' />
                <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Qantas-Logo.png' alt='Airline LOGO' />
                <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Singapore%20Airlines.png' alt='Airline LOGO' />
                <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/SpiceJet-Logo.png' alt='Airline LOGO' />
                <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Swiss-International-Air-Lines-logo.png' alt='Airline LOGO' />
                <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Turkish_Airlines_logo.png' alt='Airline LOGO' />
                <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Vistara%20logo.png' alt='Airline LOGO' />
                <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/WestJet-Logo.wine.png' alt='Airline LOGO' />
                <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/airline%20logos/Wizz_Air_Logo.png' alt='Airline LOGO' />
            </Marquee>
            <div className='text-center py-20 xl:text-6xl md:text-4xl max-sm:text-4xl max-md:text-4xl font-semibold text-indigo-700'>
                <p>Find Your Future</p>
            </div>
            <div className="flex justify-center py-5">
                <div className="grid grid-cols-1 md:grid-cols-2 max-sm:grid-cols-1 sm:grid-cols-2 gap-2">
                    <div className="relative flex flex-col items-center">
                        <img src='https://ik.imagekit.io/mhcockpit1/Pilot%20Training%20-%20BOX%201.webp' alt='future' className="w-full h-auto borde" />
                        <div className="absolute bottom-1/3 right-10 max-sm:right-8.5 transform flex flex-col items-center">
                            <a href="/training#pilot-training">
                                <button className="bg-indigo-700 text-white px-4 py-2 rounded">
                                    Read More
                                </button>
                            </a>
                            <a href='/training#pilot-training' className="text-white px-4 py-2 rounded">
                                Pilot Training
                            </a>
                        </div>
                    </div>
                    <div className="relative flex flex-col items-center space-y-9">
                        <img src='https://ik.imagekit.io/mhcockpit1/Degree%20Programs%20-%20BOX%202.webp' alt='future' className="w-full h-auto" />
                        <div className="absolute bottom-1/3 right-8 max-sm:right-5 transform flex flex-col items-center">
                            <a href="/training#degree-programs">
                                <button className="bg-indigo-700 text-white px-4 py-2 rounded">
                                    Read More
                                </button>
                            </a>
                            <a href='/training#degree-programs' className="text-black px-4 py-2 rounded">
                                Degree Programs
                            </a>
                        </div>
                    </div>
                    <div className="relative flex flex-col items-center space-y-9">
                        <img src='https://ik.imagekit.io/mhcockpit1/Diploma%20Program.jpg' alt='future' className="w-full h-auto" />
                        <div className="absolute bottom-1/3 right-8 max-sm:right-5 transform flex flex-col items-center">
                            <a href="/training#diploma-programs">
                                <button className="bg-indigo-700 text-white px-4 py-2 rounded">
                                    Read More
                                </button>
                            </a>
                            <a href='/training#diploma-programs' className="text-white px-4 py-2 rounded">
                                Diploma Program
                            </a>
                        </div>
                    </div>
                    <div className="relative flex flex-col items-center space-y-9">
                        <img src='https://ik.imagekit.io/mhcockpit1/IVs%20&%20OJTs%20-%20BOX%204.webp' alt='future' className="w-full h-auto" />
                        <div className="absolute bottom-1/3 right-10 max-sm:right-8.5 transform flex flex-col items-center">
                            <a href="https://mhintellect.com" target='blank'>
                                <button className="bg-indigo-700 text-white px-4 py-2 rounded">
                                    Read More
                                </button>
                            </a>
                            <a href='https://mhintellect.com' target='blank' className="text-white px-4 py-2 rounded">
                                MH Intellect
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <LMS />
            <div className='xl:block max-xl:block 2xl:block max-2xl:block md:hidden max-md:block lg:block max-lg:block sm:hidden max-sm:hidden pt-20'>
                <Testimonials />
            </div>
            <div className='xl:hidden max-xl:hidden 2xl:hidden max-2xl:hidden max-md:hidden lg:hidden max-lg:hidden md:block sm:block max-sm:block'>
                <TestimonialsMobile />
            </div>
            <div className='flex justify-center items-center py-10'>
                <a href='/campus' className='border border-gray-500 px-5 py-1 text-gray-400 font-semibold rounded-md tracking-wide hover:tracking-widest transition-all duration-700 ease-in-out'>We have a lot of happy <span className='text-blue-950'>students</span></a>
            </div>
            <FadeInSectionDownToUp>
                <div>
                    <p className='text-justify xl:px-52 md:px-28 max-md:px-20 max-sm:px-5'>Inspiring Testimonials From Our Graduates! Hear how MH Cockpit's top-notch training and unwavering support have shaped successful careers in aviation. Join us and embark on your journey to success today!</p>
                </div>
            </FadeInSectionDownToUp>
            <div className='flex justify-center items-center'>
                <img className='w-6/12 px-10 py-5 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/home%20Swarna%2032-LPA.png' alt='33 LPA' />
            </div>
            <Marquee speed={200}>
                <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/placed-students/Swarna.png' alt='Placed Students' />
                <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/placed-students/Joshua.png' alt='Placed Students' />
                <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/placed-students/sanjay.png' alt='Placed Students' />
                <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/placed-students/yahkoob.png' alt='Placed Students' />
                <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/placed-students/Hariharan.png' alt='Placed Students' />
                <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/placed-students/Merlin.png' alt='Placed Students' />
                <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/placed-students/Priyanka.png' alt='Placed Students' />
                <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/placed-students/sneha.png' alt='Placed Students' />
                <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/placed-students/Nivetha.png' alt='Placed Students' />
                <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/placed-students/adil.png' alt='Placed Students' />
                <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/placed-students/Ragapriya.png' alt='Placed Students' />
                <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/placed-students/Prasanth.png' alt='Placed Students' />
                <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/placed-students/Raqueeb.png' alt='Placed Students' />
                <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/placed-students/Henry.png' alt='Placed Students' />
                <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/placed-students/Irshath.png' alt='Placed Students' />
                <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/placed-students/Sneha-g.png' alt='Placed Students' />
                <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/placed-students/Shabin.png' alt='Placed Students' />
                <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/placed-students/Alsherif.png' alt='Placed Students' />
                <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/placed-students/Akilan.png' alt='Placed Students' />
                <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/placed-students/silvio.png' alt='Placed Students' />
                <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/placed-students/Gurukaviya.png' alt='Placed Students' />
                <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/placed-students/Saran.png' alt='Placed Students' />
                <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/placed-students/vlog-viki.png' alt='Placed Students' />
                <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/placed-students/bala.png' alt='Placed Students' />
                <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/placed-students/rehan.png' alt='Placed Students' />
                <img className='w-60 px-10 py-5 max-sm:w-36 max-sm:py-5 max-sm:px-5' src='https://ik.imagekit.io/mhcockpit1/placed-students/Sana.png' alt='Placed Students' />
            </Marquee>
        </div>
    )
}
