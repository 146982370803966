import React, { useState } from 'react';
import FadeInSectionDownToUp from '../../Components/FadeAnimations/FadeInSectionDownToUp';
import GalleryModal from '../../Components/Gallery/GalleryModal';

export default function Gallery() {

    // Create two arrays: one for images 112-121 and another for images 1-111
    const firstPartImages = Array.from({ length: 11 }, (_, i) => ({
        src: `https://ik.imagekit.io/mhcockpit1/Gallery/(${i + 112}).webp`,
    }));

    const secondPartImages = Array.from({ length: 111 }, (_, i) => ({
        src: `https://ik.imagekit.io/mhcockpit1/Gallery/%20%20(${i + 1}).webp`,
    }));

    const thirdPartImages = Array.from({ length: 27 }, (_, i) => ({
        src: `https://ik.imagekit.io/mhcockpit1/Gallery/(${i + 123}).webp`,
    }));

    // Concatenate both parts to get the desired order
    const GalleryImages = [...thirdPartImages, ...firstPartImages, ...secondPartImages];

    // Filter out images with indices 113 and 114 (i.e., images with src "113" and "114")
    const filteredGalleryImages = GalleryImages.filter((image, index) => {
        const imageIndex = index + 1;  // The first image starts from index 112
        return imageIndex !== 1 && imageIndex !== 3 && imageIndex !== 4 && imageIndex !== 5 && imageIndex !== 6 && imageIndex !== 7 && imageIndex !== 8 && imageIndex !== 9 && imageIndex !== 11 && imageIndex !== 12 && imageIndex !== 13 && imageIndex !== 15 && imageIndex !== 16 && imageIndex !== 17 && imageIndex !== 18 && imageIndex !== 19 && imageIndex !== 20 && imageIndex !== 21 && imageIndex !== 23 && imageIndex !== 24 && imageIndex !== 25 && imageIndex !== 26 && imageIndex !== 27;
    });

    const [modalImageSrc, setModalImageSrc] = useState(null);

    const openModal = (src) => {
        setModalImageSrc(src);
    };

    const closeModal = () => {
        setModalImageSrc(null);
    };

    return (
        <div>
            <div className="relative text-justify">
                <div className="inset-0 overflow-hidden">
                    <img
                        className='w-full h-full object-cover animate-zoomOut'
                        src="https://ik.imagekit.io/mhcockpit1/GALLERY.webp"
                        alt="DGCA - License conversion"
                    />
                </div>
                <div className="relative py-10">
                    <div className='grid grid-cols-3 max-sm:grid-cols-2 gap-10 max-sm:gap-3 px-20 max-sm:px-3 py-5'>
                        {filteredGalleryImages.map((image, index) => (
                            <div key={index}>
                                <FadeInSectionDownToUp>
                                    <img
                                        src={image.src}
                                        alt='Gallery'
                                        onClick={() => openModal(image.src)}
                                    />
                                </FadeInSectionDownToUp>
                            </div>
                        ))}
                    </div>
                    <GalleryModal
                        isOpen={!!modalImageSrc}
                        imageSrc={modalImageSrc}
                        onClose={closeModal}
                    />
                </div>
            </div>
        </div>
    );
}

